"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventType = exports.Severity = void 0;
var Severity;
(function (Severity) {
    Severity["error"] = "error";
    Severity["info"] = "info";
    Severity["success"] = "success";
    Severity["warning"] = "warning";
})(Severity = exports.Severity || (exports.Severity = {}));
var EventType;
(function (EventType) {
    EventType["delete"] = "delete";
    EventType["create"] = "create";
    EventType["update"] = "update";
    EventType["upload"] = "upload";
    EventType["load"] = "load";
    EventType["login"] = "login";
    EventType["restPassword"] = "restPassword";
    EventType["registerUser"] = "registerUser";
    EventType["confirmAccount"] = "confirmAccount";
    EventType["changePassword"] = "changePassword";
})(EventType = exports.EventType || (exports.EventType = {}));
