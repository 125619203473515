"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var checkUrl = function (url) {
    if (url) {
        var checkedUrl = url;
        if (checkedUrl.search('http') === -1) {
            checkedUrl = "http://".concat(url);
        }
        return checkedUrl;
    }
    else {
        return '';
    }
};
exports.default = checkUrl;
