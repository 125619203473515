"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.postingRootReducer = void 0;
var redux_1 = require("redux");
var common_reducer_1 = require("./common/common.reducer");
var posting_reducer_1 = require("./posting/posting.reducer");
var facebook_reducer_1 = require("./facebook/facebook.reducer");
var instagram_reducer_1 = require("./instagram/instagram.reducer");
var googleMyBusiness_reducer_1 = require("./googleMyBusiness/googleMyBusiness.reducer");
var linkedIn_reducer_1 = require("./linkedIn/linkedIn.reducer");
exports.postingRootReducer = (0, redux_1.combineReducers)({
    facebookStore: facebook_reducer_1.facebookReducer,
    instagramStore: instagram_reducer_1.instagramReducer,
    linkedInStore: linkedIn_reducer_1.linkedInReducer,
    googleMyBusinessStore: googleMyBusiness_reducer_1.googleMyBusinessReducer,
    commonStore: common_reducer_1.CommonReducer,
    postingStore: posting_reducer_1.PostingReducer,
});
