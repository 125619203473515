"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var material_1 = require("@mui/material");
var styles_1 = require("@mui/styles");
var react_router_dom_1 = require("react-router-dom");
var react_i18next_1 = require("react-i18next");
var models_1 = require("@professocialApi/professocial-core-frontend-api/models");
var useStyles = (0, styles_1.makeStyles)(function (theme) { return ({
    tabs: {
        position: 'sticky',
        width: '100%',
        top: theme.spacing(10),
        zIndex: 98,
        backgroundColor: theme.palette.primary.main,
        '& .MuiTabs-indicator': { height: '3px', backgroundColor: theme.palette.common.white },
        '& .MuiTab-root': { fontSize: '1rem', padding: theme.spacing(1.5, 2.5), color: theme.palette.primary.contrastText },
        '& .Mui-selected': { color: theme.palette.primary.contrastText, fontWeight: 700 },
    },
}); });
var NavigationBar = function (_a) {
    var _b;
    var user = _a.user, navigationList = _a.navigationList, naviChanged = _a.naviChanged, setNaviChange = _a.setNaviChange;
    var classes = useStyles();
    var history = (0, react_router_dom_1.useHistory)();
    var t = (0, react_i18next_1.useTranslation)().t;
    var _c = react_1.default.useState(false), value = _c[0], setValue = _c[1];
    var connections = (_b = user === null || user === void 0 ? void 0 : user.smConnections) === null || _b === void 0 ? void 0 : _b.find(function (connection) { return connection.state === models_1.SocialmediaConnectionState.CONNECTED; });
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    (0, react_1.useEffect)(function () {
        if (navigationList && navigationList[0]) {
            var pathname = window.location.pathname;
            var path_1 = pathname.split('/');
            var hasLocation = navigationList.find(function (item) { return item.key === path_1[2]; });
            if (hasLocation) {
                setValue(hasLocation.key);
            }
            else {
                path_1.includes('login') && user && history.push("/".concat(user ? user.role.toLowerCase() : 'user', "/"));
                setValue(navigationList[0].key);
            }
        }
    }, [navigationList]);
    (0, react_1.useEffect)(function () {
        !naviChanged && setValue(false);
    }, [naviChanged]);
    var navToSettingsValue = function (value) { return connections ? value : 'settings/connect'; };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(material_1.Tabs, { value: value, indicatorColor: 'secondary', className: classes.tabs, onChange: handleChange }, navigationList && navigationList.map(function (navItem) {
            return (react_1.default.createElement(material_1.Tab, { component: react_router_dom_1.Link, to: "/".concat(user ? user.role.toLowerCase() : 'user', "/").concat((user === null || user === void 0 ? void 0 : user.role) === 'USER' ? navToSettingsValue(navItem.value) : navItem.value), label: "".concat(t('coreNavigation:' + navItem.label)), value: navItem.key, key: navItem.key, onClick: function () { return setNaviChange && setNaviChange(true); } }));
        }))));
};
exports.default = NavigationBar;
