"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var locale_1 = require("@mui/material/locale");
var getAppTheme = function () {
    var _a;
    var defaultTheme = {
        typography: {
            fontFamily: [
                'Roboto Condensed',
                'Roboto',
                '"Helvetica"',
                'Arial',
                'sans-serif',
            ].join(','),
            subtitle1: {
                fontFamily: 'Roboto Condensed',
                textTransform: 'uppercase',
                fontWeight: 'inherit',
                fontSize: 16,
            },
        },
        palette: {
            primary: {
                main: '#646464',
            },
            secondary: {
                main: '#FFFFFF',
            },
            info: {
                main: '#646464',
            },
            background: {
                default: '#ebebeb',
                paper: '#ffffff',
            },
        },
        components: {
            MuiTypography: {
                styleOverrides: {
                    body1: {
                        fontFamily: 'Roboto Condensed',
                        fontSize: '16px',
                    },
                    body2: {
                        fontFamily: 'Roboto Condensed',
                        fontSize: '14px',
                    },
                    h3: {
                        fontFamily: 'Roboto Condensed',
                        fontSize: '18px',
                    },
                    h4: {
                        fontFamily: 'Roboto Condensed',
                        fontSize: '16px',
                        fontWeight: 700,
                    },
                    h5: {
                        fontFamily: 'Roboto Condensed',
                        fontSize: '14px',
                        fontWeight: 700,
                    },
                },
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        border: '1px solid #b5b5b5',
                        background: 'transparent',
                        width: '100%',
                        height: 'fit-content',
                        boxShadow: 'none',
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        color: '#646464',
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        height: '19px!IMPORTANT',
                        '&:invalid': {
                            border: 0,
                        },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: '#707070',
                    },
                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        marginTop: 0,
                    },
                },
            },
        },
    };
    var palettePrimary = (_a = defaultTheme === null || defaultTheme === void 0 ? void 0 : defaultTheme.palette) === null || _a === void 0 ? void 0 : _a.primary;
    palettePrimary.main = '#2e4b81';
    if (defaultTheme && defaultTheme.components && defaultTheme.components.MuiButton && defaultTheme.components.MuiButton.styleOverrides) {
        defaultTheme.components.MuiButton.styleOverrides = {
            textPrimary: {
                color: '#2e4b81',
                border: 0,
                '&:hover': {
                    color: '#565656',
                    border: 0,
                },
            },
            textSecondary: {
                color: '#565656',
                border: 0,
                '&:hover': {
                    color: '#2e4b81',
                    border: 0,
                },
            },
            containedPrimary: {
                color: '#FFFFFF',
                backgroundColor: '#2e4b81',
                border: '1px solid #2e4b81',
                '&:hover': {
                    color: '#2e4b81',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #2e4b81',
                },
            },
            containedSecondary: {
                color: '#FFFFFF',
                backgroundColor: '#565656',
                border: '1px solid #565656',
                '&:hover': {
                    color: '#565656',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #565656',
                },
            },
        };
    }
    var theme = (0, material_1.createTheme)(defaultTheme, locale_1.deDE);
    return theme;
};
exports.default = getAppTheme;
