"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUpdateImprintAutomaticallyValue = exports.getCoverMediaFile = exports.getProfileMediaFile = exports.getProfileImages = exports.getCoverImages = exports.getMediaData = exports.getMediaFileMetadata = exports.getPotentialPageImprint = exports.getAllFacebookCategories = exports.getFacebookPage = void 0;
var reselect_1 = require("reselect");
exports.getFacebookPage = (0, reselect_1.createSelector)(function (state) { return state.facebookStore.facebookPage; }, function (state) {
    return state;
});
exports.getAllFacebookCategories = (0, reselect_1.createSelector)(function (state) { return state.facebookStore.categories; }, function (state) {
    return state;
});
exports.getPotentialPageImprint = (0, reselect_1.createSelector)(function (state) { return state.facebookStore.potentialImprint; }, function (state) {
    return state;
});
exports.getMediaFileMetadata = (0, reselect_1.createSelector)(function (state) { return state.facebookStore.mediaFileMetadata; }, function (state) {
    return state;
});
exports.getMediaData = (0, reselect_1.createSelector)(function (state) { return state.facebookStore.mediaFiles; }, function (state) { return state; });
exports.getCoverImages = (0, reselect_1.createSelector)(function (state) { return state.facebookStore.coverImages; }, function (state) { return state; });
exports.getProfileImages = (0, reselect_1.createSelector)(function (state) { return state.facebookStore.profileImages; }, function (state) { return state; });
exports.getProfileMediaFile = (0, reselect_1.createSelector)(function (state) { return state.facebookStore.profileMediaFile; }, function (state) { return state; });
exports.getCoverMediaFile = (0, reselect_1.createSelector)(function (state) { return state.facebookStore.coverMediaFile; }, function (state) { return state; });
exports.getUpdateImprintAutomaticallyValue = (0, reselect_1.createSelector)(function (state) { return state.facebookStore.updateImprintAutomatically; }, function (state) { return state; });
