"use strict";
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.commonSagas = void 0;
var effects_1 = require("redux-saga/effects");
var typescript_fsa_redux_saga_1 = require("typescript-fsa-redux-saga");
var instances_1 = require("../../api/instances");
var common_actions_1 = require("./common.actions");
var instances_2 = require("@professocial/professocialAccounts/src/api/instances");
// logout
var logoutWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.logout)(function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.AutorisationROApi, instances_1.AutorisationROApi.userLogout])];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchLogoutInitiated() {
    var action, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 9];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.logoutInitiated)];
            case 1:
                action = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestStarted)({ type: action.type }))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                _a.trys.push([3, 6, , 8]);
                return [4 /*yield*/, logoutWorker(action.payload)];
            case 4:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFinished)({ type: action.type }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 8];
            case 6:
                e_1 = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFailed)({ type: action.type, error: e_1 }))];
            case 7:
                _a.sent();
                console.error('error in watchLogoutInitiated: ', e_1);
                return [3 /*break*/, 8];
            case 8: return [3 /*break*/, 0];
            case 9: return [2 /*return*/];
        }
    });
}
// getCurrentUser
var getUserAccountWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.getCurrentUser)(function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.AutorisationROApi, instances_1.AutorisationROApi.getCurrentUser])];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchGetUserAccountInitiated() {
    var action, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.getCurrentUserInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, getUserAccountWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_2 = _a.sent();
                console.error('error in watchGetUserAccountInitiated: ', e_2);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// initiateFacebookConnect
var initiateFacebookConnectWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.initiateFacebookConnect)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.SmPublishConnectApi, instances_1.SmPublishConnectApi.initiateConnect], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchInitiateFacebookConnectInitiated() {
    var action, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 9];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.initiateFacebookConnectInitiated)];
            case 1:
                action = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestStarted)({ type: action.type }))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                _a.trys.push([3, 6, , 8]);
                return [4 /*yield*/, initiateFacebookConnectWorker(action.payload)];
            case 4:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFinished)({ type: action.type }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 8];
            case 6:
                e_3 = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFailed)({ type: action.type, error: e_3 }))];
            case 7:
                _a.sent();
                console.error('error in watchInitiateFacebookConnectInitiated: ', e_3);
                return [3 /*break*/, 8];
            case 8: return [3 /*break*/, 0];
            case 9: return [2 /*return*/];
        }
    });
}
// initiateInstagramConnect
var initiateInstagramConnectWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.initiateInstagramConnect)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.SmPublishConnectApi, instances_1.SmPublishConnectApi.initiateConnect], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchInitiateInstagramConnectInitiated() {
    var action, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 9];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.initiateInstagramConnectInitiated)];
            case 1:
                action = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestStarted)({ type: action.type }))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                _a.trys.push([3, 6, , 8]);
                return [4 /*yield*/, initiateInstagramConnectWorker(action.payload)];
            case 4:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFinished)({ type: action.type }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 8];
            case 6:
                e_4 = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFailed)({ type: action.type, error: e_4 }))];
            case 7:
                _a.sent();
                console.error('error in watchInitiateInstagramConnectInitiated: ', e_4);
                return [3 /*break*/, 8];
            case 8: return [3 /*break*/, 0];
            case 9: return [2 /*return*/];
        }
    });
}
var initiateGetPageIdentifierWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.getPageIdentifierForCurrentUser)(function (params) {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.UserHasPageApi, instances_1.UserHasPageApi.getPageIdentifiersForCurrentUser])];
            case 1:
                result = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)(common_actions_1.getPageIdentifierForCurrentUser.done({ result: result }))];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
});
function watchGetPageIdentifierForCurrentUserInitiated() {
    var action, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.getPageIdentifierForCurrentUserInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, initiateGetPageIdentifierWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_5 = _a.sent();
                console.error('error in watchGetPageIdentifierForCurrentUserInitiated: ', e_5);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// initiateGoogleConnect
var initiateGoogleConnectWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.initiateGoogleConnect)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.SmPublishConnectApi, instances_1.SmPublishConnectApi.initiateConnect], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchInitiateGoogleConnectInitiated() {
    var action, e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.initiateGoogleConnectInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, initiateGoogleConnectWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_6 = _a.sent();
                console.error('error in watchInitiateInstagramConnectInitiated: ', e_6);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// getFacebookConnectData
var getFacebookConnectDataWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.getFacebookConnectData)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.FacebookConnectApi, instances_1.FacebookConnectApi.getFacebookConnectData], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchGetFacebookConnectDataInitiated() {
    var action, e_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 9];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.getFacebookConnectDataInitiated)];
            case 1:
                action = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestStarted)({ type: action.type }))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                _a.trys.push([3, 6, , 8]);
                return [4 /*yield*/, getFacebookConnectDataWorker(action.payload)];
            case 4:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFinished)({ type: action.type }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 8];
            case 6:
                e_7 = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFailed)({ type: action.type, error: e_7 }))];
            case 7:
                _a.sent();
                console.error('error in watchGetFacebookConnectDataInitiated: ', e_7);
                return [3 /*break*/, 8];
            case 8: return [3 /*break*/, 0];
            case 9: return [2 /*return*/];
        }
    });
}
// getInstagramConnectData
var getInstagramConnectDataWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.getInstagramConnectData)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.InstagramConnectApi, instances_1.InstagramConnectApi.getInstagramConnectData], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchGetInstagramConnectDataInitiated() {
    var action, e_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.getInstagramConnectDataInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, getInstagramConnectDataWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_8 = _a.sent();
                console.error('error in watchGetInstagramConnectDataInitiated: ', e_8);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// performConnect
var performConnectWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.performConnect)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.SmPublishConnectApi, instances_1.SmPublishConnectApi.performConnect], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchPerformConnectInitiated() {
    var action, e_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.performConnectInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, performConnectWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_9 = _a.sent();
                console.error('error in watchPerformConnectInitiated: ', e_9);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// performDisconnect
var performDisconnectWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.performDisconnect)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.SmPublishConnectApi, instances_1.SmPublishConnectApi.performDisconnect], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchPerformDisconnectInitiated() {
    var action, e_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.performDisconnectInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, performDisconnectWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_10 = _a.sent();
                console.error('error in watchPerformDisconnectInitiated: ', e_10);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// getSmPublishAuthToken
var getSmPublishAuthTokenWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.getSmPublishAuthToken)(function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.SmPublishAuthApi, instances_1.SmPublishAuthApi.getSmPublishAuthToken])];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchGetSmPublishAuthTokenInitiated() {
    var action, e_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.getSmPublishAuthTokenInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, getSmPublishAuthTokenWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_11 = _a.sent();
                console.error('error in watchGetSmPublishAuthTokenInitiated: ', e_11);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// socialmediaUserConnect
var socialmediaUserConnectWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.socialmediaUserConnect)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.SocialmediaUserApi, instances_1.SocialmediaUserApi.socialmediaUserConnect], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchSocialmediaUserConnectInitiated() {
    var action, e_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.socialmediaUserConnectInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, socialmediaUserConnectWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_12 = _a.sent();
                console.error('error in watchSocialmediaUserConnectInitiated: ', e_12);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// socialmediaUserDisconnect
var socialmediaUserDisconnectWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.socialmediaUserDisconnect)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.SocialmediaUserApi, instances_1.SocialmediaUserApi.socialmediaUserDisconnect], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchSocialmediaUserDisconnectInitiated() {
    var action, e_13;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.socialmediaUserDisconnectInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, socialmediaUserDisconnectWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_13 = _a.sent();
                console.error('error in watchSocialmediaUserDisconnectInitiated: ', e_13);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// socialmediaEditorConnect
var socialmediaEditorConnectWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.socialmediaEditorConnect)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.SocialmediaEditorApi, instances_1.SocialmediaEditorApi.socialmediaEditorConnect], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchSocialmediaEditorConnectInitiated() {
    var action, e_14;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.socialmediaEditorConnectInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, socialmediaEditorConnectWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_14 = _a.sent();
                console.error('error in watchSocialmediaEditorConnectInitiated: ', e_14);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// socialmediaEditorDisconnect
var socialmediaEditorDisconnectWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.socialmediaEditorDisconnect)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.SocialmediaEditorApi, instances_1.SocialmediaEditorApi.socialmediaEditorDisconnect], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchSocialmediaEditorDisconnectInitiated() {
    var action, e_15;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.socialmediaEditorDisconnectInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, socialmediaEditorDisconnectWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_15 = _a.sent();
                console.error('error in watchSocialmediaEditorDisconnectInitiated: ', e_15);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// updateUserAccount
var updateUserAccountWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.updateUserAccount)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_2.UserLoginRESTServiceApi, instances_2.UserLoginRESTServiceApi.updateUserAccount], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchUpdateUserAccountInitiated() {
    var action, e_16;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 9];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.updateUserAccountInitiated)];
            case 1:
                action = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestStarted)({ type: action.type }))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                _a.trys.push([3, 6, , 8]);
                return [4 /*yield*/, updateUserAccountWorker(action.payload)];
            case 4:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFinished)({ type: action.type }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 8];
            case 6:
                e_16 = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFailed)({ type: action.type, error: e_16 }))];
            case 7:
                _a.sent();
                console.error('error in watchUpdateUserAccountInitiated: ', e_16);
                return [3 /*break*/, 8];
            case 8: return [3 /*break*/, 0];
            case 9: return [2 /*return*/];
        }
    });
}
// getPotentialPageImprint
var getPotentialPageImprintWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.getPotentialPageImprint)(function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.ImprintAPI, instances_1.ImprintAPI.getPotentialPageImprint])];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchGetPotentialPageImprintInitiated() {
    var action, e_17;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.getPotentialPageImprintInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, getPotentialPageImprintWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_17 = _a.sent();
                console.error('error in watchGetPotentialPageImprintInitiated: ', e_17);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// setOrUpdateImprint
var setOrUpdateImprintWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.setOrUpdateImprint)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.ImprintAPI, instances_1.ImprintAPI.setOrUpdateImprint], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchSetOrUpdateImprintInitiated() {
    var action, e_18;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.setOrUpdateImprintInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, setOrUpdateImprintWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_18 = _a.sent();
                console.error('error in watchSetOrUpdateImprintInitiated: ', e_18);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// login
var userLoginWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.userLogin)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.AutorisationROApi, instances_1.AutorisationROApi.userLogin], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchUserLoginInitiated() {
    var action, e_19;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 9];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.userLoginInitiated)];
            case 1:
                action = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestStarted)({ type: action.type }))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                _a.trys.push([3, 6, , 8]);
                return [4 /*yield*/, userLoginWorker(action.payload)];
            case 4:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFinished)({ type: action.type }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 8];
            case 6:
                e_19 = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFailed)({ type: action.type, error: e_19 }))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [3 /*break*/, 0];
            case 9: return [2 /*return*/];
        }
    });
}
// getPageIdentifiersForUser
var getPageIdentifiersForUserWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(common_actions_1.getPageIdentifiersForUser)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.UserHasPageApi, instances_1.UserHasPageApi.getPageIdentifiersForUser], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchGetPageIdentifiersForUserInitiated() {
    var action, e_20;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(common_actions_1.getPageIdentifiersForUserInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, getPageIdentifiersForUserWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_20 = _a.sent();
                console.error('error in watchGetPageIdentifiersForUserInitiated: ', e_20);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
function commonSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.fork)(watchLogoutInitiated)];
            case 1:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchGetUserAccountInitiated)];
            case 2:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchGetPageIdentifierForCurrentUserInitiated)];
            case 3:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchInitiateFacebookConnectInitiated)];
            case 4:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchInitiateInstagramConnectInitiated)];
            case 5:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchInitiateGoogleConnectInitiated)];
            case 6:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchGetFacebookConnectDataInitiated)];
            case 7:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchGetInstagramConnectDataInitiated)];
            case 8:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchPerformConnectInitiated)];
            case 9:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchPerformDisconnectInitiated)];
            case 10:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchGetSmPublishAuthTokenInitiated)];
            case 11:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchSocialmediaUserConnectInitiated)];
            case 12:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchSocialmediaUserDisconnectInitiated)];
            case 13:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchSocialmediaEditorConnectInitiated)];
            case 14:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchSocialmediaEditorDisconnectInitiated)];
            case 15:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchUpdateUserAccountInitiated)];
            case 16:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchGetPotentialPageImprintInitiated)];
            case 17:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchSetOrUpdateImprintInitiated)];
            case 18:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchUserLoginInitiated)];
            case 19:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchGetPageIdentifiersForUserInitiated)];
            case 20:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.commonSagas = commonSagas;
