"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getThumbnailForMediaFile = exports.getUploadedFile = exports.getUploadedFileId = exports.getSettingMediaFileUsage = exports.getTemporaryMediaFile = exports.getMediaFile = exports.getProfileImages = exports.getCoverImages = exports.getMediaData = void 0;
var reselect_1 = require("reselect");
exports.getMediaData = (0, reselect_1.createSelector)(function (state) { return state.imagesStore.mediaFiles; }, function (state) { return state; });
exports.getCoverImages = (0, reselect_1.createSelector)(function (state) { return state.imagesStore.coverImages; }, function (state) { return state; });
exports.getProfileImages = (0, reselect_1.createSelector)(function (state) { return state.imagesStore.profileImages; }, function (state) { return state; });
exports.getMediaFile = (0, reselect_1.createSelector)(function (state) { return state.imagesStore.mediaFile; }, function (state) { return state; });
exports.getTemporaryMediaFile = (0, reselect_1.createSelector)(function (state) { return state.imagesStore.temporaryMediaFile; }, function (state) { return state; });
exports.getSettingMediaFileUsage = (0, reselect_1.createSelector)(function (state) { return state.imagesStore.settingMediaFileUsage; }, function (state) { return state; });
exports.getUploadedFileId = (0, reselect_1.createSelector)(function (state) { return state.imagesStore.uploadedFileId; }, function (state) { return state; });
exports.getUploadedFile = (0, reselect_1.createSelector)(function (state) { return state.imagesStore.uploadedFile; }, function (state) { return state; });
exports.getThumbnailForMediaFile = (0, reselect_1.createSelector)(function (state) { return state.imagesStore.thumbnailForMediaFile; }, function (state) { return state; });
