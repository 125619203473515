"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatisticsEditorReducer = void 0;
var typescript_fsa_reducers_1 = require("typescript-fsa-reducers");
var immer_1 = require("immer");
var statistics_editor_actions_1 = require("./statistics.editor.actions");
var INITIAL_STATE = {
    fetching: false,
    error: {},
};
exports.StatisticsEditorReducer = (0, typescript_fsa_reducers_1.reducerWithInitialState)(INITIAL_STATE)
    /**
     * getEditorFacebookPageStatistics
     */
    .case(statistics_editor_actions_1.getEditorFacebookPageStatistics.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getFacebookPageStatistics;
    });
})
    .case(statistics_editor_actions_1.getEditorFacebookPageStatistics.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.facebookPageStatistics = result;
        draft.fetching = false;
    });
})
    .case(statistics_editor_actions_1.getEditorFacebookPageStatistics.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getFacebookPageStatistics = error;
    });
})
    /**
     * getEditorInstagramPageStatistics
     */
    .case(statistics_editor_actions_1.getEditorInstagramPageStatistics.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getInstagramPageStatistics;
    });
})
    .case(statistics_editor_actions_1.getEditorInstagramPageStatistics.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.instagramPageStatistics = result;
        draft.fetching = false;
    });
})
    .case(statistics_editor_actions_1.getEditorInstagramPageStatistics.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getInstagramPageStatistics = error;
    });
})
    /**
     * getFacebookPostsInRangeForMandator
     */
    .case(statistics_editor_actions_1.getFacebookPostsInRangeForMandator.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getFacebookPostsInRangeForPage;
    });
})
    .case(statistics_editor_actions_1.getFacebookPostsInRangeForMandator.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.facebookPostsInRange = result;
        draft.fetching = false;
    });
})
    .case(statistics_editor_actions_1.getFacebookPostsInRangeForMandator.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getFacebookPostsInRangeForPage = error;
    });
})
    /**
     * getInstagramPostsInRangeForPage
     */
    .case(statistics_editor_actions_1.getInstagramPostsInRangeForMandator.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getInstagramPostsInRangeForPage;
    });
})
    .case(statistics_editor_actions_1.getInstagramPostsInRangeForMandator.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.instagramPostsInRange = result;
        draft.fetching = false;
    });
})
    .case(statistics_editor_actions_1.getInstagramPostsInRangeForMandator.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getInstagramPostsInRangeForPage = error;
    });
})
    /**
       * exportFacebookPageStatisticsForRange
       */
    .case(statistics_editor_actions_1.exportFacebookPageStatisticsForRange.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getInstagramPostsInRangeForPage;
    });
})
    .case(statistics_editor_actions_1.exportFacebookPageStatisticsForRange.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.facebookExportedStatistics = result;
        draft.fetching = false;
    });
})
    .case(statistics_editor_actions_1.exportFacebookPageStatisticsForRange.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getInstagramPostsInRangeForPage = error;
    });
})
    /**
     * exportInstagramPageStatisticsForRange
     */
    .case(statistics_editor_actions_1.exportInstagramPageStatisticsForRange.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getInstagramPostsInRangeForPage;
    });
})
    .case(statistics_editor_actions_1.exportInstagramPageStatisticsForRange.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.instagramExportedStatistics = result;
        draft.fetching = false;
    });
})
    .case(statistics_editor_actions_1.exportInstagramPageStatisticsForRange.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getInstagramPostsInRangeForPage = error;
    });
})
    /**
       * getInsightsForPublishedPosts
       */
    .case(statistics_editor_actions_1.getInsightsForPublishedPosts.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.aggregatedPublishedPostsInsights = result;
    });
})
    /**
       * getInsightsForPublishedPostsBefore
       */
    .case(statistics_editor_actions_1.getInsightsForPublishedPostsBefore.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.aggregatedPublishedPostsInsightsBefore = result;
    });
})
    /**
       * getPublishedTemplateCount
       */
    .case(statistics_editor_actions_1.getPublishedTemplateCount.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.aggregatedPublishedTemplateCounts = result;
    });
})
    /**
       * getPublishedPostsByTemplateCount
       */
    .case(statistics_editor_actions_1.getPublishedPostsByTemplateCount.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.aggregatedPublishedPostsByTemplateCounts = result;
    });
})
    /**
       * exportPublishedPostsCountForTemplateStatistics
       */
    .case(statistics_editor_actions_1.exportPublishedPostsCountForTemplateStatistics.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.aggregatedPublishedTemplateExportedStatistics = result;
    });
})
    /**
       * exportPublishedTemplatesByCreatorForRange
       */
    .case(statistics_editor_actions_1.exportPublishedTemplatesByCreatorForRange.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.aggregatedPublishedPostsExportedStatistics = result;
    });
})
    /**
       * getInsightsForPublishedTemplatesInRange
       */
    .case(statistics_editor_actions_1.getInsightsForPublishedTemplatesInRange.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.publishedTemplatesStatistics = result;
    });
});
