"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDebugEnv = exports.BASE_PATH = void 0;
exports.BASE_PATH = window.location.origin.search('localhost') !== -1 ? (window.localStorage.getItem('basePath') || '') : "".concat(window.location.origin);
var devEnv = {
    restBase: exports.BASE_PATH,
};
var stageEnv = __assign({}, devEnv);
var prodEnv = __assign({}, devEnv);
var getEnvValues = function () {
    switch (process.env.BUILD_ENV) {
        case 'production':
            return prodEnv;
        case 'staging':
            return stageEnv;
    }
    return devEnv;
};
exports.isDebugEnv = process.env.BUILD_ENV !== undefined
    ? process.env.BUILD_ENV === 'development'
    : process.env.NODE_ENV === 'development';
exports.default = getEnvValues();
