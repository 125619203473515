"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDayDifference = void 0;
/**
 * Return the day difference between two dates
 * @param date1 Date
 * @param date2 Date
 */
var getDayDifference = function (date1, date2) {
    return Math.floor((date1.getTime() - date2.getTime()) / (1000 * 3600 * 24));
};
exports.getDayDifference = getDayDifference;
