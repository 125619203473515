"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAuditLogForNetworkPage = exports.getAboHasPostings = exports.getReNewSettingsStatus = exports.getTemporaryMediaFile = exports.getUploadStatus = exports.getAboSettings = exports.getAboTopics = void 0;
var reselect_1 = require("reselect");
exports.getAboTopics = (0, reselect_1.createSelector)(function (state) { return state.topicStore.aboTopics; }, function (state) { return state; });
exports.getAboSettings = (0, reselect_1.createSelector)(function (state) { return state.topicStore.aboSettings; }, function (state) { return state; });
exports.getUploadStatus = (0, reselect_1.createSelector)(function (state) { return state.topicStore.uploadPending; }, function (state) { return state; });
exports.getTemporaryMediaFile = (0, reselect_1.createSelector)(function (state) { return state.topicStore.temporaryFileMetadata; }, function (state) { return state; });
exports.getReNewSettingsStatus = (0, reselect_1.createSelector)(function (state) { return state.topicStore.reNewSettings; }, function (state) { return state; });
exports.getAboHasPostings = (0, reselect_1.createSelector)(function (state) { return state.topicStore.aboHasPostings; }, function (state) { return state; });
exports.getAuditLogForNetworkPage = (0, reselect_1.createSelector)(function (state) { return state.topicStore.auditLogForCurrentUser; }, function (state) { return state; });
