"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPageIdentifiers = exports.getFacebookConnectHash = exports.getAboSettings = exports.onSuccess = exports.onError = exports.getAllSubsidiaries = exports.getAllUserAccounts = exports.getUserAccount = void 0;
var reselect_1 = require("reselect");
exports.getUserAccount = (0, reselect_1.createSelector)(function (state) { return state.accountsStore.userAccount; }, function (state) { return state; });
exports.getAllUserAccounts = (0, reselect_1.createSelector)(function (state) { return state.accountsStore.allUserAccounts; }, function (state) { return state; });
exports.getAllSubsidiaries = (0, reselect_1.createSelector)(function (state) { return state.accountsStore.allSubsidiaries; }, function (state) { return state; });
exports.onError = (0, reselect_1.createSelector)(function (state) { return state.accountsStore.error; }, function (state) { return state; });
exports.onSuccess = (0, reselect_1.createSelector)(function (state) { return state.accountsStore.saveSuccess; }, function (state) { return state; });
exports.getAboSettings = (0, reselect_1.createSelector)(function (state) { return state.accountsStore.aboSettings; }, function (state) { return state; });
exports.getFacebookConnectHash = (0, reselect_1.createSelector)(function (state) { return state.accountsStore.facebookConnectHash; }, function (state) { return state; });
exports.getPageIdentifiers = (0, reselect_1.createSelector)(function (state) { return state.accountsStore.pageIdentifiers; }, function (state) { return state; });
