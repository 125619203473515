"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetXf2mToken = exports.GetXf2mToken = void 0;
var xf2mTokenKey = 'X-F2M-AuthToken';
// in the unlikely case of "sessionStorage.setItem(undefined)" -> undefined will be set as string
// this should not happen, but it did while using aws functions which should have returned a string
// to prevent corrupt headers, we have to cleanup the getters or setters
var GetXf2mToken = function () {
    var xf2mToken = sessionStorage.getItem(xf2mTokenKey);
    return xf2mToken === 'undefined' ? null : xf2mToken;
};
exports.GetXf2mToken = GetXf2mToken;
var SetXf2mToken = function (token) { return sessionStorage.setItem(xf2mTokenKey, token); };
exports.SetXf2mToken = SetXf2mToken;
/**
 * Extended fetch API method to handles:
 * - XSRF tokens
 */
var smModuleFetchApi = function (fetch) { return function (url, init) { return __awaiter(void 0, void 0, void 0, function () {
    var xf2mToken, requestInit, response, newXsrfToken;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                xf2mToken = (0, exports.GetXf2mToken)();
                requestInit = __assign(__assign({}, init), { credentials: 'include', headers: __assign(__assign({}, (init ? init.headers : undefined)), (xf2mToken ? { 'X-F2M-AuthToken': xf2mToken } : undefined)) });
                return [4 /*yield*/, fetch(url, requestInit)
                    // check if we have a Xf2m token in the http response header
                ];
            case 1:
                response = _a.sent();
                newXsrfToken = response.headers.get('X-F2M-AuthToken');
                if (newXsrfToken)
                    (0, exports.SetXf2mToken)(newXsrfToken);
                return [2 /*return*/, response];
        }
    });
}); }; };
exports.default = smModuleFetchApi;
