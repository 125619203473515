{
    "topicsCommon": {
        "changingInfo": "Diese Einstellung wurde durch Anpassungen des Themenkanals automatisch geändert.",
        "whereAreYou": "Du bist hier",
        "automaticReleases": "Automatische Veröffentlichungen",
        "backToOverview": "Zurück zur Übersicht",
        "changeTopicTitle": "Themenkanal bearbeiten",
        "changeTopic": "Änderungen übernehmen",
        "createTopic": "Neuen Themenkanal anlegen",
        "createCategory": "Neue Vorlagen-Kategorie anlegen",
        "noCategories": "Noch keine Vorlagen-Kategorie vorhanden",
        "noTopics": "Noch keine Themenkanäle vorhanden",
        "createTopicTitle": "Neuen Themenkanal anlegen",
        "defaultTopic": "Standardkanal",
        "description": "Beschreiben Sie diesen Themenkanal",
        "topicOf": "Themenkanal von",
        "topic": "Themenkanal",
        "activate": "Überall aktivieren",
        "individualReleases": "Individuelle Veröffentlichungen",
        "overviewTitle": "Themenkanäle",
        "delete": "Löschen",
        "edit": "Bearbeiten",
        "active": "Kanal aktiv",
        "automaticPostingAllowed": "Themenkanal ist automatisch abonnierbar",
        "isInvisibleForUser": "Kanal ist für den Nutzer nicht sichtbar",
        "manualPostingAllowed": "Kanal ist manuell abonnierbar",
        "automaticDeactivationAllowed": "Themenkanal ist deaktivierbar",
        "mandatoryTopic": "Pflichtkanal",
        "defaultPostingType": "Standard Abonnement",
        "name": "Titel des Themenkanals",
        "titleRequired": "Titel des Themenkanals ist erforderlich",
        "descRequired": "Beschreibung des Themenkanals ist erforderlich",
        "imageRequired": "Bild des Themenkanals ist erforderlich",
        "subscribe": "Kanal abonnieren",
        "subscribed": "Kanal ist abonniert",
        "subscribedAll": "Alle Kanäle sind abonniert",
        "subscribeAll": "Alle Kanäle abonnieren",
        "setOnline": "Automatisch veröffentlichen",
        "Off": "Aus",
        "Individual": "Manuell",
        "Automatic": "Auto",
        "aboEmail": "Abo-E-Mail Adresse für Benachrichtigungen",
        "aboEmailShort": "Abo-E-Mail Adresse",
        "save": "Speichern",
        "cancel": "Abbrechen",
        "topicDescriptionFirst": "Nachfolgend können Sie die Posting-Themenkanäle abonnieren. Wenn Sie Postings aus einem Thema immer direkt auf Ihre Socialmedia Seite gepostet haben möchten, wählen Sie bitte 'Automatisch veröffentlichen' aus.",
        "topicDescriptionSecond": "Es ist zu beachten, dass alle Themenkanäle für jeden Socialmedia Kanal separat eingestellt werden müssen.",
        "ok": "Ok",
        "subMenuFacebook": "Facebook",
        "subMenuInstagram": "Instagram",
        "subMenuGoogleMyBusiness": "GoogleMyBusiness",
        "temporaryMediaFile": "Temporäre Mediendatei",
        "TopicsAndMetadataForCurrentUser": "Themenkanäle und Metadaten für den aktuellen Nutzer",
        "aboSettingsForCurrentUser": "Abo-Einstellungen für den aktuellen Benutzer",
        "topics": "Themenkanäle",
        "category": "Kategorien",
        "categories": "Vorlagen-Kategorie"
    },
    "topicsMessages": {
        "error": "Fehler",
        "deleteWarningTitle": "Achtung!",
        "cannotDelete": "Die Abotopic wird noch verwendet und kann nicht gelöscht werden.",
        "deleteTopicWarning": "Wollen Sie den Themenkanal wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
        "topicIsSuccessfullyCreated": "Themenkanal wurde erfolgreich erstellt!",
        "topicCreateError": "Themenkanal konnte nicht erstellt werden!",
        "topicIsSuccessfullyUpdated": "Themenkanal wurde erfolgreich geändert!",
        "topicEditError": "Themenkanal konnte nicht geändert werden!",
        "topicIsSuccessfullyDeleted": "Themenkanal wurde erfolgreich gelöscht!",
        "topicDeleteError": "Themenkanal konnte nicht gelöscht werden!",
        "getTopicsAndMetadataForCurrentUserError": "Die Themenkanäle konnten nicht geladen werden.",
        "isSuccessfullyCreated": "{{context}} wurde erfolgreich erstellt!",
        "createError": "{{context}} konnte nicht erstellt werden!",
        "isSuccessfullyDeleted": "{{context}} wurde erfolgreich gelöscht!",
        "deleteError": "{{context}} konnte nicht gelöscht werden!",
        "isSuccessfullyUpdated": "{{context}} wurde erfolgreich aktualisiert!",
        "updateError": "{{context}} konnte nicht aktualisiert werden!",
        "areSuccessfullyUpdated": "{{context}} wurden erfolgreich aktualisiert!",
        "updatingError": "{{context}} konnten nicht aktualisiert werden!",
        "loadingError": "{{context}} konnten nicht geladen werden!",
        "editSettingsRequestTitle": "Achtung!",
        "changeDeferredSettingsRequest": "Bereits geplante Beiträge sind von der Anpassung des Abonnements betroffen. Sollen diese Beiträge automatisch angepasst werden?",
        "changeSettingsRequest": "Die Veröffentlichungseinstellungen eines Themenkanals haben sich geändert.",
        "editSettingsRequest": "Die Veröffentlichungseinstellungen dieses Themenkanals haben sich geändert. Soll diese Einstellung bei allen Nutzern angewendet werden?",
        "requestYes": "Ja",
        "requestNo": "Nein",
        "loadError": "{{context}} konnte nicht geladen werden!"
    }
}
