"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImagesReducer = void 0;
var typescript_fsa_reducers_1 = require("typescript-fsa-reducers");
var immer_1 = require("immer");
var images_actions_1 = require("./images.actions");
var INITIAL_STATE = {
    fetching: false,
    settingMediaFileUsage: false,
    error: {},
};
exports.ImagesReducer = (0, typescript_fsa_reducers_1.reducerWithInitialState)(INITIAL_STATE)
    .case(images_actions_1.setUploadedFileId, function (state, uploadedFileId) {
    return (__assign(__assign({}, state), { uploadedFileId: uploadedFileId }));
})
    .case(images_actions_1.setUploadedMediaFile, function (state, mediaFile) {
    return (__assign(__assign({}, state), { mediaFile: mediaFile }));
})
    /**
     * getMediaFileMetadata
     */
    .case(images_actions_1.getMediaFileMetadata.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getMediaFileMetadata;
    });
})
    .case(images_actions_1.getMediaFileMetadata.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.mediaFile = result;
        draft.fetching = false;
    });
})
    .case(images_actions_1.getMediaFileMetadata.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getMediaFileMetadata = error;
    });
})
    /**
     * uploadMediaFile
     */
    .case(images_actions_1.uploadMediaFile.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.uploadMediaFile;
    });
})
    .case(images_actions_1.uploadMediaFile.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.mediaFile = result;
    });
})
    .case(images_actions_1.uploadMediaFile.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.uploadMediaFile = error;
    });
})
    /**
       * uploadThumbnailForMediaFile
       */
    .case(images_actions_1.uploadThumbnailForMediaFile.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.uploadMediaFile;
    });
})
    .case(images_actions_1.uploadThumbnailForMediaFile.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        var _a;
        var index = (_a = state.mediaFiles) === null || _a === void 0 ? void 0 : _a.findIndex(function (item) { return item.mediaFileId === result.mediaFileId; });
        if (index && draft.mediaFiles) {
            draft.mediaFiles[index] = result;
        }
        draft.thumbnailForMediaFile = result;
    });
})
    .case(images_actions_1.uploadThumbnailForMediaFile.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.uploadMediaFile = error;
    });
})
    /**
     * uploadTemporaryMediaFile
     */
    .case(images_actions_1.uploadTemporaryMediaFile.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.loadingTMFile = true;
        delete draft.error.uploadTemporaryMediaFile;
    });
})
    .case(images_actions_1.uploadTemporaryMediaFile.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.temporaryMediaFile = result;
        draft.fetching = false;
        draft.loadingTMFile = result && false;
    });
})
    .case(images_actions_1.uploadTemporaryMediaFile.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.loadingTMFile = false;
        draft.error.uploadTemporaryMediaFile = error;
    });
})
    /**
     * deleteMediaFile
     */
    .case(images_actions_1.deleteMediaFile.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.deleteMediaFile;
    });
})
    .case(images_actions_1.deleteMediaFile.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(images_actions_1.deleteMediaFile.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.deleteMediaFile = error;
    });
})
    /**
     * listMediaFiles
     */
    .case(images_actions_1.listMediaFiles.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.listMediaFiles;
    });
})
    .case(images_actions_1.listMediaFiles.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.mediaFiles = result;
        draft.fetching = false;
    });
})
    .case(images_actions_1.listMediaFiles.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.listMediaFiles = error;
    });
})
    /**
     * listProfileImages
     */
    .case(images_actions_1.listProfileImages.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.listProfileImages;
    });
})
    .case(images_actions_1.listProfileImages.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.profileImages = result;
        draft.fetching = false;
    });
})
    .case(images_actions_1.listProfileImages.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.listProfileImages = error;
    });
})
    /**
     * listProfileImages
     */
    .case(images_actions_1.listCoverImages.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.listCoverImages;
    });
})
    .case(images_actions_1.listCoverImages.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.coverImages = result;
        draft.fetching = false;
    });
})
    .case(images_actions_1.listCoverImages.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.listCoverImages = error;
    });
})
    /**
     * setMediaUsage
     */
    .case(images_actions_1.setMediaFileUsage.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.settingMediaFileUsage = true;
        delete draft.error.uploadMediaFile;
    });
})
    .case(images_actions_1.setMediaFileUsage.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.settingMediaFileUsage = false;
    });
})
    .case(images_actions_1.setMediaFileUsage.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.settingMediaFileUsage = false;
        draft.error.setMediaUsage = error;
    });
})
    /**
     * checkFileUpload
     */
    .case(images_actions_1.checkFileUpload.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.uploadMediaFile;
    });
})
    .case(images_actions_1.checkFileUpload.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.uploadedFileId = result.entityId;
        draft.fetching = false;
    });
})
    .case(images_actions_1.checkFileUpload.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.setMediaUsage = error;
    });
})
    /**
     * getUploadedFileAsBase64
     */
    .case(images_actions_1.getUploadedFileAsBase64.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.uploadMediaFile;
    });
})
    .case(images_actions_1.getUploadedFileAsBase64.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.uploadedFile = result;
        draft.fetching = false;
    });
})
    .case(images_actions_1.getUploadedFileAsBase64.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.setMediaUsage = error;
    });
});
