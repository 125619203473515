"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var material_1 = require("@mui/material");
var styles_1 = require("@mui/styles");
var useStyles = (0, styles_1.makeStyles)(function (theme) { return ({
    tabMenuMargin: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    subMenu: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1, 4),
        textTransform: 'uppercase',
        cursor: 'pointer',
    },
    subMenuText: {
        whiteSpace: 'nowrap',
        fontSize: '15px',
        height: '20px',
        fontFamily: theme.typography.fontFamily,
    },
    activeItem: {
        borderBottom: "3px solid ".concat(theme.palette.primary.main),
        color: theme.palette.primary.main,
        '& svg': {
            fill: theme.palette.primary.main,
        },
    },
    disabled: {
        color: theme.palette.text.disabled,
        pointerEvents: 'none',
        '& svg': {
            fill: theme.palette.text.disabled,
        },
    },
    inactiveItem: {
        color: theme.palette.text.primary,
        '&:hover': {
            borderBottom: "3px solid ".concat(theme.palette.primary.main),
            color: theme.palette.primary.main,
            '& svg': {
                fill: theme.palette.primary.main,
            },
        },
    },
}); });
var TabMenu = function (props) {
    var classes = useStyles();
    var _a = (0, react_1.useState)(), activeItem = _a[0], setActiveItem = _a[1];
    (0, react_1.useEffect)(function () {
        setActiveItem(props.activeItem);
    }, [props.activeItem]);
    return (react_1.default.createElement(material_1.Grid, { container: true, spacing: 1, className: classes.tabMenuMargin }, props.tabs.map(function (tab, index) {
        var setClassStatus = activeItem === tab.name ? classes.activeItem : tab.disabled ? classes.disabled : classes.inactiveItem;
        return (react_1.default.createElement(material_1.Grid, { key: index, item: true },
            react_1.default.createElement("div", { className: "".concat(classes.subMenu, " ").concat(setClassStatus), onClick: function () {
                    props.onChangeTab && props.onChangeTab(tab.name);
                } },
                tab.icon,
                react_1.default.createElement(material_1.Typography, { className: classes.subMenuText }, tab.label))));
    })));
};
exports.default = TabMenu;
