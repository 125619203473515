"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HelpReducer = void 0;
var typescript_fsa_reducers_1 = require("typescript-fsa-reducers");
var immer_1 = require("immer");
var help_actions_1 = require("./help.actions");
var INITIAL_STATE = {
    fetching: false,
    error: {},
};
exports.HelpReducer = (0, typescript_fsa_reducers_1.reducerWithInitialState)(INITIAL_STATE)
    .case(help_actions_1.setHelpPost, function (state, helpPost) {
    return (__assign(__assign({}, state), { helpPost: helpPost }));
})
    /**
       * getHelpPost
       */
    .case(help_actions_1.getHelpPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getHelpPost;
    });
})
    .case(help_actions_1.getHelpPost.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft = __assign(__assign({}, draft), { helpPost: result });
        draft.fetching = false;
        return draft;
    });
})
    .case(help_actions_1.getHelpPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getHelpPost = error;
    });
})
    /**
       * getHelpPostList
       */
    .case(help_actions_1.getHelpPostList.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getIgPage;
    });
})
    .case(help_actions_1.getHelpPostList.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft = __assign(__assign({}, draft), { helpPosts: result });
        draft.fetching = false;
        return draft;
    });
})
    .case(help_actions_1.getHelpPostList.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getHelpPostList = error;
    });
})
    /**
       * createEditorHelpPost
       */
    .case(help_actions_1.createEditorHelpPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getIgPage;
    });
})
    .case(help_actions_1.createEditorHelpPost.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.helpPost = result;
        draft.fetching = false;
        return draft;
    });
})
    .case(help_actions_1.createEditorHelpPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getHelpPostList = error;
    });
})
    /**
       * deleteEditorHelpPost
       */
    .case(help_actions_1.deleteEditorHelpPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getIgPage;
    });
})
    .case(help_actions_1.deleteEditorHelpPost.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.helpPost = result;
        draft.fetching = false;
        return draft;
    });
})
    .case(help_actions_1.deleteEditorHelpPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getHelpPostList = error;
    });
})
    /**
       * getEditorHelpPost
       */
    .case(help_actions_1.getEditorHelpPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getHelpPost;
    });
})
    .case(help_actions_1.getEditorHelpPost.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.helpPost = result;
        draft.fetching = false;
        return draft;
    });
})
    .case(help_actions_1.getEditorHelpPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getHelpPost = error;
    });
})
    /**
       * updateEditorHelpPost
       */
    .case(help_actions_1.updateEditorHelpPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getHelpPost;
    });
})
    .case(help_actions_1.updateEditorHelpPost.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.helpPost = result;
        draft.fetching = false;
        return draft;
    });
})
    .case(help_actions_1.updateEditorHelpPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getHelpPost = error;
    });
});
