"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.statisticsRootReducer = void 0;
var redux_1 = require("redux");
var common_reducer_1 = require("./common/common.reducer");
var statistics_user_reducer_1 = require("./statistics/statistics.user.reducer");
var statistics_editor_reducer_1 = require("./statistics/statistics.editor.reducer");
exports.statisticsRootReducer = (0, redux_1.combineReducers)({
    commonStore: common_reducer_1.CommonReducer,
    statisticsUserStore: statistics_user_reducer_1.StatisticsUserReducer,
    statisticsEditorStore: statistics_editor_reducer_1.StatisticsEditorReducer,
});
