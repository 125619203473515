"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.googleMyBusinessReducer = void 0;
var typescript_fsa_reducers_1 = require("typescript-fsa-reducers");
var immer_1 = require("immer");
var googleMyBusiness_actions_1 = require("./googleMyBusiness.actions");
var messageTypes_1 = require("@professocial/professocialTypes/types/messageTypes");
var INITIAL_STATE = {
    fetching: false,
    error: {},
};
var triggerProfessocialDeletePostSuccess = function (data) {
    window.dispatchEvent(new CustomEvent('ProfessocialDeletePostSuccess', { detail: data }));
};
var triggerProfessocialPostingSuccess = function (data) {
    window.dispatchEvent(new CustomEvent('ProfessocialPostingSuccess', { detail: data }));
};
var triggerProfessocialMessage = function (data) {
    window.dispatchEvent(new CustomEvent('ProfessocialMessage', { detail: data }));
};
exports.googleMyBusinessReducer = (0, typescript_fsa_reducers_1.reducerWithInitialState)(INITIAL_STATE)
    /**
     * getGooglePage
     */
    .case(googleMyBusiness_actions_1.getGooglePage.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getGooglePage;
    });
})
    .case(googleMyBusiness_actions_1.getGooglePage.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.googleMyBusinessPage = result;
        draft.fetching = false;
    });
})
    .case(googleMyBusiness_actions_1.getGooglePage.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getGooglePage = error;
    });
})
    /**
     * getGooglePageFeed
     */
    .case(googleMyBusiness_actions_1.getGooglePageFeed.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getGooglePageFeed;
    });
})
    .case(googleMyBusiness_actions_1.getGooglePageFeed.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.googleFeeds = result;
        draft.fetching = false;
    });
})
    .case(googleMyBusiness_actions_1.getGooglePageFeed.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getGooglePageFeed = error;
    });
})
    /**
       * deletePost
       */
    .case(googleMyBusiness_actions_1.deleteGooglePost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.deleteGooglePost;
    });
})
    .case(googleMyBusiness_actions_1.deleteGooglePost.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        triggerProfessocialDeletePostSuccess(true);
    });
})
    .case(googleMyBusiness_actions_1.deleteGooglePost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.deleteGooglePost = error;
        triggerProfessocialMessage({
            severity: messageTypes_1.Severity.error,
            message: 'Error',
            eventType: messageTypes_1.EventType.delete,
        });
    });
})
    /**
     * confirmManualDeferredPost
     */
    .case(googleMyBusiness_actions_1.confirmManualDeferredPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.confirmManualDeferredPost;
    });
})
    .case(googleMyBusiness_actions_1.confirmManualDeferredPost.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        triggerProfessocialPostingSuccess(true);
    });
})
    .case(googleMyBusiness_actions_1.confirmManualDeferredPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.confirmManualDeferredPost = error;
        triggerProfessocialMessage({
            severity: messageTypes_1.Severity.error,
            message: 'Error',
            eventType: messageTypes_1.EventType.update,
        });
    });
})
    /**
     * declineManualDeferredPost
     */
    .case(googleMyBusiness_actions_1.declineManualDeferredPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.declineManualDeferredPost;
    });
})
    .case(googleMyBusiness_actions_1.declineManualDeferredPost.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        triggerProfessocialDeletePostSuccess(true);
    });
})
    .case(googleMyBusiness_actions_1.declineManualDeferredPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.declineManualDeferredPost = error;
        triggerProfessocialMessage({
            severity: messageTypes_1.Severity.error,
            message: 'Error',
            eventType: messageTypes_1.EventType.delete,
        });
    });
})
    /**
     * deleteManualDeferredPost
     */
    .case(googleMyBusiness_actions_1.deleteManualDeferredPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.deleteManualDeferredPost;
    });
})
    .case(googleMyBusiness_actions_1.deleteManualDeferredPost.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        triggerProfessocialDeletePostSuccess(true);
    });
})
    .case(googleMyBusiness_actions_1.deleteManualDeferredPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.deleteManualDeferredPost = error;
        triggerProfessocialMessage({
            severity: messageTypes_1.Severity.error,
            message: 'Error',
            eventType: messageTypes_1.EventType.delete,
        });
    });
})
    /**
     * retryManualDeferredPost
     */
    .case(googleMyBusiness_actions_1.retryManualDeferredPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.retryManualDeferredPost;
    });
})
    .case(googleMyBusiness_actions_1.retryManualDeferredPost.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        triggerProfessocialPostingSuccess(true);
    });
})
    .case(googleMyBusiness_actions_1.retryManualDeferredPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.retryManualDeferredPost = error;
        triggerProfessocialMessage({
            severity: messageTypes_1.Severity.error,
            message: 'Error',
            eventType: messageTypes_1.EventType.delete,
        });
    });
})
    /**
     * updateDeferredPost
     */
    .case(googleMyBusiness_actions_1.updateDeferredPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.updateDeferredPost;
    });
})
    .case(googleMyBusiness_actions_1.updateDeferredPost.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(googleMyBusiness_actions_1.updateDeferredPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.updateDeferredPost = error;
        triggerProfessocialMessage({
            severity: messageTypes_1.Severity.error,
            message: 'Error',
            eventType: messageTypes_1.EventType.delete,
        });
    });
});
