"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.topicRootReducer = void 0;
var redux_1 = require("redux");
var common_reducer_1 = require("./common/common.reducer");
var topic_reducer_1 = require("./topic/topic.reducer");
exports.topicRootReducer = (0, redux_1.combineReducers)({
    commonStore: common_reducer_1.CommonReducer,
    topicStore: topic_reducer_1.TopicReducer,
});
