"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCaptcha = exports.onFailedUserRegistration = exports.isLogged = exports.onSuccess = exports.onError = exports.getRedirectPath = exports.getMessage = exports.getUser = void 0;
var reselect_1 = require("reselect");
exports.getUser = (0, reselect_1.createSelector)(function (state) { return state.userStore.user; }, function (state) { return state; });
exports.getMessage = (0, reselect_1.createSelector)(function (state) { return state.userStore.message; }, function (state) { return state; });
exports.getRedirectPath = (0, reselect_1.createSelector)(function (state) { return state.userStore.redirectPath; }, function (state) { return state; });
exports.onError = (0, reselect_1.createSelector)(function (state) { return state.userStore.error; }, function (state) { return state; });
exports.onSuccess = (0, reselect_1.createSelector)(function (state) { return state.userStore.success; }, function (state) { return state; });
exports.isLogged = (0, reselect_1.createSelector)(function (state) { return state.userStore.isLogged; }, function (state) { return state; });
exports.onFailedUserRegistration = (0, reselect_1.createSelector)(function (state) { return state.userStore.onRegistration; }, function (state) { return state; });
exports.getCaptcha = (0, reselect_1.createSelector)(function (state) { return state.userStore.captcha; }, function (state) { return state; });
