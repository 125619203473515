{
  "common": {
    "administration": "Verwaltung",
    "projects": "Projekte",
    "quickAccess": "VPS Schnellzugriff",
    "editProfile": "Profil bearbeiten",
    "settings": "Einstellungen",
    "logout": "Abmelden",
    "editLayout": "Layout bearbeiten",
    "options": "Optionen"
  },
  "settings": {
    "username": "Nutzername",
    "email": "E-Mail-Adresse",
    "name": "Klartextname",
    "role": "Rolle",
    "phone": "Telefon",
    "customer": "Kunde",
    "changePassword": "Passwort ändern",
    "oldPassword": "Altes Passwort",
    "newPassword": "Neues Passwort",
    "confirmPassword": "Neues Passwort wiederholen"
  },
  "login": {
    "welcome": "Willkommen bei Professocial!",
    "forgotPassword": "PASSWORT VERGESSEN?",
    "login": "LOGIN",
    "password": "Passwort",
    "username": "Benutzername",
    "register": "NOCH KEIN ACCOUNT? JETZT REGISTRIEREN!",
    "registrationForm": "Registrieren",
    "resetPassword": "Passwort zurücksetzen",
    "cancel": "zurück zum Login",
    "passwordReset": "Passwort anfordern",
    "sendRegistration": "Registrierung abschicken",
    "authentication": "Authentifizierung",
    "secretCode": "Sicherheitscode",
    "deviceName": "Gerätename",
    "secretCodes": "Sicherheitscodes",
    "twoFactorAuth": "Zwei-Faktor-Authentifizierung",
    "registerInAuthApp": "Registrieren Sie jetzt eine Authenticator App.",
    "enterCode": "Geben Sie den ersten Code zur Bestätigung ein.",
    "device": "Bitte wählen Sie einen Gerätenamen aus.",
    "activate": "Aktivieren",
    "secretManagement": "Secret-Verwaltung",
    "registrationTitle": "Registrierung - Facebook-Fanseiten-Tool",
    "enterCaptcha": "Geben Sie das obige Wort ein"
  }
}
