"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var detectFormatLinks = function (text) {
    if (!text) {
        return '';
    }
    text = text.replace('<script>', '[script]');
    var urlRegex = /(https?:\/\/[^\s<>)\]}]+)/g;
    text = text.replace(urlRegex, function (url) { return "<a href=\"".concat(url, "\" target=\"_blank\">").concat(url, "</a>"); });
    var urlRegexMail = /([^\s>\n]+@[^!?\s<]+)/g;
    text = text.replace(urlRegexMail, function (mail) { return "<a href=\"mailto:".concat(mail, "\">").concat(mail, "</a>"); });
    var hashtagRegex = /(^|\s|>)#[^\s<]+/;
    var hashtagRegexGlobal = /(^|\s|)#[^\s<]+/g;
    text = text.replace(hashtagRegexGlobal, function (hashtag) {
        var array = hashtag.match(hashtagRegex);
        var firstChar = array ? array[1] : '';
        return "".concat(firstChar, "<a href=\"https://www.facebook.com/hashtag/").concat(hashtag.replace(/\n/g, '').replace(/ /g, '').replace('#', '').trim(), "\" target=\"_blank\">").concat(hashtag.trim(), "</a>");
    });
    var mentionRegex = /(^|\s)@\[([^:]+):([^:]+):([^\]]+)]/;
    var mentionRegexGlobal = /(^|\s)@\[([^:]+):([^:]+):([^\]]+)]/g;
    text = text.replace(mentionRegexGlobal, function (mention) {
        var match = mention.match(mentionRegex);
        // if there is a whitespace character at the start of the mention, preserve it
        var firstChar = match ? match[1] : '';
        // id of the mentioned facebook page
        var pageId = match ? match[2] : '';
        // weird number with unknown purpose
        // const unknownId = match ? match[3] : ''
        // name of the facebook page, to be displayed as the link label
        var name = match ? match[4] : '';
        var result = "".concat(firstChar, "<a href=\"https://www.facebook.com/").concat(pageId, "\" target=\"_blank\">").concat(name, "</a>");
        return result;
    });
    return text;
};
exports.default = detectFormatLinks;
