"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.facebookReducer = void 0;
var typescript_fsa_reducers_1 = require("typescript-fsa-reducers");
var immer_1 = require("immer");
var facebook_actions_1 = require("./facebook.actions");
var INITIAL_STATE = {
    fetching: false,
    error: {},
};
exports.facebookReducer = (0, typescript_fsa_reducers_1.reducerWithInitialState)(INITIAL_STATE)
    /**
     * getFacebookPage
     */
    .case(facebook_actions_1.getFacebookPage.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getFacebookPage;
    });
})
    .case(facebook_actions_1.getFacebookPage.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.facebookPage = result;
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.getFacebookPage.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getFacebookPage = error;
    });
})
    /**
     * getFacebookFeedForPage
     */
    .case(facebook_actions_1.getFacebookFeedForPage.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.loadFacebookFeedForPage;
    });
})
    .case(facebook_actions_1.getFacebookFeedForPage.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.facebookFeeds = result;
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.getFacebookFeedForPage.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.loadFacebookFeedForPage = error;
    });
})
    /**
     * deletePost
     */
    .case(facebook_actions_1.deletePost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.deletePost;
    });
})
    .case(facebook_actions_1.deletePost.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.deletePost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.deletePost = error;
    });
})
    /**
     * deleteFacebookPostComment
     */
    .case(facebook_actions_1.deleteFacebookPostComment.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.deletePostComment;
    });
})
    .case(facebook_actions_1.deleteFacebookPostComment.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.deleteFacebookPostComment.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.deletePostComment = error;
    });
})
    /**
     * likeFacebookPost
     */
    .case(facebook_actions_1.likeFacebookPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.likePost;
    });
})
    .case(facebook_actions_1.likeFacebookPost.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.likeFacebookPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.likePost = error;
    });
})
    /**
     * likeFacebookPostComment
     */
    .case(facebook_actions_1.likeFacebookPostComment.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.likePostComment;
    });
})
    .case(facebook_actions_1.likeFacebookPostComment.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.likeFacebookPostComment.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.likePostComment = error;
    });
})
    /**
     * unlikeFacebookPost
     */
    .case(facebook_actions_1.unlikeFacebookPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.unlikePost;
    });
})
    .case(facebook_actions_1.unlikeFacebookPost.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.unlikeFacebookPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.unlikePost = error;
    });
})
    /**
     * unlikeFacebookPostComment
     */
    .case(facebook_actions_1.unlikeFacebookPostComment.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.unlikePostComment;
    });
})
    .case(facebook_actions_1.unlikeFacebookPostComment.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.unlikeFacebookPostComment.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.unlikePostComment = error;
    });
})
    /**
     * writeFacebookPostComment
     */
    .case(facebook_actions_1.writeFacebookPostComment.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.writePostComment;
    });
})
    .case(facebook_actions_1.writeFacebookPostComment.done, function (state, _a) {
    var result = _a.result;
    // eslint-disable-next-line no-unused-vars
    var newFeeds = state.facebookFeeds && state.facebookFeeds.posts;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.writeFacebookPostComment.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.writePostComment = error;
    });
})
    /**
     * getFacebookPost
     */
    .case(facebook_actions_1.getFacebookPost.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getFacebookPost;
    });
})
    .case(facebook_actions_1.getFacebookPost.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.getFacebookPost.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getFacebookPost = error;
    });
})
    /**
     * answerToFacebookPostComment
     */
    .case(facebook_actions_1.answerToFacebookPostComment.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.answerToPostComment;
    });
})
    .case(facebook_actions_1.answerToFacebookPostComment.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.answerToFacebookPostComment.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.answerToPostComment = error;
    });
});
