"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDateAsString = void 0;
function getDateAsString(date) {
    if (date) {
        var day = new Date(date).getDate();
        var dayString = day.toString();
        if (day < 10) {
            dayString = "0".concat(dayString);
        }
        var month = new Date(date).getMonth() + 1;
        var monthString = month.toString();
        if (month < 10) {
            monthString = "0".concat(monthString);
        }
        var year = new Date(date).getFullYear();
        return "".concat(dayString, ".").concat(monthString, ".").concat(year);
    }
    else
        return '';
}
exports.getDateAsString = getDateAsString;
