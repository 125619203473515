{
  "common": {
    "administration": "Administration",
    "projects": "Projects",
    "quickAccess": "VPS quick access",
    "editProfile": "Edit profile",
    "settings": "Settings",
    "logout": "Logout",
    "editLayout": "Edit layout"
  },
  "settings": {
    "username": "Username",
    "email": "E-Mail",
    "name": "Full Name",
    "role": "Role",
    "phone": "Phone",
    "customer": "Customer",
    "changePassword": "Change password",
    "oldPassword": "Old password",
    "newPassword": "New password",
    "confirmPassword": "Confirm password"
  }
}
