"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopicReducer = void 0;
var typescript_fsa_reducers_1 = require("typescript-fsa-reducers");
var immer_1 = require("immer");
var topic_actions_1 = require("./topic.actions");
var INITIAL_STATE = {
    fetching: false,
    reNewSettings: false,
    uploadPending: false,
    aboHasPostings: {},
    error: {},
};
exports.TopicReducer = (0, typescript_fsa_reducers_1.reducerWithInitialState)(INITIAL_STATE)
    /**
     * createAboTopic
     */
    .case(topic_actions_1.createAboTopic.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.createAboTopic;
    });
})
    .case(topic_actions_1.createAboTopic.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.temporaryFileMetadata = undefined;
    });
})
    .case(topic_actions_1.createAboTopic.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.createAboTopic = error;
    });
})
    /**
     * deleteAboTopic
     */
    .case(topic_actions_1.deleteAboTopic.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        // draft.aboTopicStatus = AboTopicStatus.pending
        delete draft.error.deleteAboTopic;
    });
})
    .case(topic_actions_1.deleteAboTopic.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        // draft.aboTopicStatus = AboTopicStatus.successDelete
    });
})
    .case(topic_actions_1.deleteAboTopic.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.deleteAboTopic = error;
    });
})
    /**
     * getTopicsAndMetadataForCurrentUser
     */
    .case(topic_actions_1.getTopicsAndMetadataForCurrentUser.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getTopicsAndMetadataForCurrentUser;
    });
})
    .case(topic_actions_1.getTopicsAndMetadataForCurrentUser.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.aboTopics = result;
        draft.fetching = false;
    });
})
    .case(topic_actions_1.getTopicsAndMetadataForCurrentUser.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getTopicsAndMetadataForCurrentUser = error;
    });
})
    /**
     * updateAboTopic
     */
    .case(topic_actions_1.updateAboTopic.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.updateAboTopic;
    });
})
    .case(topic_actions_1.updateAboTopic.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.temporaryFileMetadata = undefined;
    });
})
    .case(topic_actions_1.updateAboTopic.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.updateAboTopic = error;
    });
})
    /**
     * getAboSettingsForCurrentUser
     */
    .case(topic_actions_1.getAboSettingsForCurrentUser.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getAboSettingsForCurrentUser;
    });
})
    .case(topic_actions_1.getAboSettingsForCurrentUser.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.aboSettings = result;
        draft.fetching = false;
        draft.reNewSettings = false;
    });
})
    .case(topic_actions_1.getAboSettingsForCurrentUser.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getAboSettingsForCurrentUser = error;
    });
})
    /**
     * updateAboSettingsForCurrentUser
     */
    .case(topic_actions_1.updateAboSettingsForCurrentUser.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.updateAboSettingsForCurrentUser;
    });
})
    .case(topic_actions_1.updateAboSettingsForCurrentUser.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.reNewSettings = true;
    });
})
    .case(topic_actions_1.updateAboSettingsForCurrentUser.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.updateAboSettingsForCurrentUser = error;
    });
})
    /**
     * uploadTemporaryMediaFile
     */
    .case(topic_actions_1.uploadTemporaryMediaFile.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        draft.uploadPending = true;
        delete draft.error.uploadTemporaryMediaFile;
    });
})
    .case(topic_actions_1.uploadTemporaryMediaFile.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.temporaryFileMetadata = result;
        draft.uploadPending = false;
        draft.fetching = false;
    });
})
    .case(topic_actions_1.uploadTemporaryMediaFile.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.uploadPending = false;
        draft.error.uploadTemporaryMediaFile = error;
    });
})
    /**
     * getAboTopicHasPostings
     */
    .case(topic_actions_1.getAboTopicHasPostings.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getAboTopicHasPostings;
    });
})
    .case(topic_actions_1.getAboTopicHasPostings.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.aboHasPostings[result.topicId] = result;
        draft.fetching = false;
    });
})
    .case(topic_actions_1.getAboTopicHasPostings.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getAboTopicHasPostings = error;
    });
})
    /**
     * getAuditLogForCurrentUser
     */
    .case(topic_actions_1.getAuditLogForNetworkPage.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getAuditLogForCurrentUser;
    });
})
    .case(topic_actions_1.getAuditLogForNetworkPage.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.auditLogForCurrentUser = result;
        draft.fetching = false;
    });
})
    .case(topic_actions_1.getAuditLogForNetworkPage.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getAuditLogForCurrentUser = error;
    });
})
    /**
     * acknowledgeSettingsForCurrentUser
     */
    .case(topic_actions_1.acknowledgeSettingsForNetworkPage.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.acknowledgeSettingsForCurrentUser;
    });
})
    .case(topic_actions_1.acknowledgeSettingsForNetworkPage.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        // draft.reNewSettings = true
        draft.aboHasPostings = {};
        draft.fetching = false;
    });
})
    .case(topic_actions_1.acknowledgeSettingsForNetworkPage.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.acknowledgeSettingsForCurrentUser = error;
    });
});
