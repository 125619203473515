"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.linkedInReducer = void 0;
var typescript_fsa_reducers_1 = require("typescript-fsa-reducers");
var immer_1 = require("immer");
var linkedIn_actions_1 = require("./linkedIn.actions");
var INITIAL_STATE = {
    error: {},
};
exports.linkedInReducer = (0, typescript_fsa_reducers_1.reducerWithInitialState)(INITIAL_STATE)
    /**
     * getFacebookFeedForPage
     */
    .case(linkedIn_actions_1.getLinkedInFeed.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        delete draft.error.loadLinkedInFeedForPage;
    });
})
    .case(linkedIn_actions_1.getLinkedInFeed.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.linkedInFeeds = result;
    });
})
    .case(linkedIn_actions_1.getLinkedInFeed.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.error.loadLinkedInFeedForPage = error;
    });
});
