"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var countAllowedNetworks = function (socialMediaNetwork) {
    var counter = 0;
    socialMediaNetwork && Object.keys(socialMediaNetwork).map(function (key) {
        if (socialMediaNetwork[key]) {
            counter += 1;
        }
    });
    return counter;
};
exports.default = countAllowedNetworks;
