"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var submenuConfig_1 = require("../config/submenuConfig");
var getSubmenu = function (path) {
    if (path) {
        switch (true) {
            // user submenus
            case path.indexOf('/user/dashboard') > -1:
                return submenuConfig_1.userDashboardSubmenu;
            case path.indexOf('/user/facebook') > -1:
                return submenuConfig_1.facebookSettingsSubmenu;
            case path.indexOf('/user/settings') > -1: {
                var index = submenuConfig_1.settingsAppSubmenu.findIndex(function (object) { return object.id === submenuConfig_1.connectTab.id; });
                if (index === -1)
                    submenuConfig_1.settingsAppSubmenu.push(submenuConfig_1.connectTab);
                return submenuConfig_1.settingsAppSubmenu;
            }
            // editor submenus
            case path.indexOf('/editor/dashboard') > -1:
                return submenuConfig_1.userDashboardSubmenu;
            case path.indexOf('/editor/images') > -1:
                return submenuConfig_1.EditorImagesSubnenu;
            case path.indexOf('/editor/settings') > -1: {
                return submenuConfig_1.settingsAppSubmenu;
            }
            // admin submenus
            case path.indexOf('/admin/settings') > -1 || path.indexOf('/editor/settings') > -1:
                return submenuConfig_1.settingsAppSubmenu;
        }
    }
};
exports.default = getSubmenu;
