"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var react_redux_1 = require("react-redux");
var common_actions_1 = require("../store/common/common.actions");
var dashboard_actions_1 = require("../store/dashboard/dashboard.actions");
var common_selectors_1 = require("../store/common/common.selectors");
var LogOut = function (props) {
    var dispatch = (0, react_redux_1.useDispatch)();
    var dispatchLogOutUser = (0, react_1.useCallback)(function () { return dispatch((0, common_actions_1.logoutInitiated)()); }, [dispatch]);
    var dispatchSetConnectedFacebookPage = (0, react_1.useCallback)(function (params) { return dispatch((0, common_actions_1.setConnectedFacebookPage)(params)); }, [dispatch]);
    var dispatchSetConnectedInstagramPage = (0, react_1.useCallback)(function (params) { return dispatch((0, common_actions_1.setConnectedInstagramPage)(params)); }, [dispatch]);
    var dispatchSetConnectedGooglePage = (0, react_1.useCallback)(function (params) { return dispatch((0, common_actions_1.setConnectedGooglePage)(params)); }, [dispatch]);
    var dispatchSetConnectedLinkedInPage = (0, react_1.useCallback)(function (params) { return dispatch((0, common_actions_1.setConnectedLinkedInPage)(params)); }, [dispatch]);
    var dispatchSetFacebookPage = (0, react_1.useCallback)(function (params) { return dispatch((0, dashboard_actions_1.setFacebookPage)(params)); }, [dispatch]);
    var dispatchSetInstagramPage = (0, react_1.useCallback)(function (params) { return dispatch((0, dashboard_actions_1.setIGPage)(params)); }, [dispatch]);
    var dispatchSetPageIdentifiersForUser = (0, react_1.useCallback)(function (params) { return dispatch((0, common_actions_1.setPageIdentifiersForUser)(params)); }, [dispatch]);
    var dispatchSetTokenChanged = (0, react_1.useCallback)(function (params) { return dispatch((0, common_actions_1.setTokenChanged)(params)); }, [dispatch]);
    var onLogOutUser = (0, react_redux_1.useSelector)(function (state) { return (0, common_selectors_1.namedRequestData)(state, common_actions_1.logoutInitiated.type); });
    (0, react_1.useEffect)(function () {
        dispatchLogOutUser();
        dispatchSetFacebookPage(undefined);
        dispatchSetInstagramPage(undefined);
        dispatchSetPageIdentifiersForUser(undefined);
        dispatchSetTokenChanged(false);
        sessionStorage.removeItem('X-F2M-AuthToken');
        sessionStorage.removeItem('XSRF-TOKEN');
        localStorage.removeItem('user');
        dispatchSetConnectedFacebookPage('');
        dispatchSetConnectedInstagramPage('');
        dispatchSetConnectedGooglePage('');
        dispatchSetConnectedLinkedInPage('');
        props.proceedLogout();
    }, []);
    return (react_1.default.createElement(react_1.default.Fragment, null, (onLogOutUser === null || onLogOutUser === void 0 ? void 0 : onLogOutUser.status) === 'success' && react_1.default.createElement(react_router_dom_1.Redirect, { exact: true, to: '/login' })));
};
exports.default = LogOut;
