"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var getDaysInMonth_1 = require("./getDaysInMonth");
var datesAreTheSame_1 = require("./datesAreTheSame");
var useDisplayCalendar = function (changeDataParams) {
    var _a = (0, react_1.useState)(), value = _a[0], setValue = _a[1];
    var handleChange = function (selectedDate) {
        var workDate = new Date(selectedDate);
        var hour = workDate.getHours();
        var minute = workDate.getMinutes();
        var month = workDate.getMonth() + 1;
        var year = workDate.getFullYear();
        var today = new Date(Date.now());
        var dayCount = (0, getDaysInMonth_1.daysInMonth)(month, year);
        workDate.setDate(1);
        var firstWeekDay = workDate.getDay();
        // Sunday - Saturday : 0 - 6
        workDate.setDate(dayCount);
        var lastWeekDay = workDate.getDay();
        var actualMonth = [];
        var actualDay = false;
        var published = false;
        var i = 1;
        while (i <= dayCount) {
            workDate.setDate(i);
            actualDay = (0, datesAreTheSame_1.datesAreOnSameDay)(workDate, today);
            published = workDate <= today;
            var weekday = workDate.getDay();
            actualMonth.push({
                month: month,
                day: i,
                year: year,
                date: new Date(year, month - 1, i, hour, minute),
                weekday: weekday,
                actualDay: actualDay,
                actualMonth: true,
                published: published,
            });
            i += 1;
        }
        var lastMonth = [];
        var nextMonth = [];
        var to = new Date();
        var from = new Date(workDate.getFullYear(), workDate.getMonth(), 1);
        // Auffüllen der vollen Wochen
        var newFirstWeek = 0;
        // if firstWeekDay on Sunday
        if (firstWeekDay === 0)
            (firstWeekDay = 7);
        while (firstWeekDay > 1) {
            firstWeekDay -= 1;
            var newMonth = month;
            var newYear = workDate.getFullYear();
            if (month === 1) {
                newMonth = 12;
                newYear -= 1;
            }
            else {
                newMonth -= 1;
            }
            var lastDayCount = (0, getDaysInMonth_1.daysInMonth)(newMonth, newYear);
            lastMonth.push({
                month: newMonth,
                day: lastDayCount - newFirstWeek,
                date: new Date(newYear, newMonth, lastDayCount - newFirstWeek),
                year: newYear,
                weekday: firstWeekDay,
                actualDay: false,
                actualMonth: false,
                published: true,
            });
            from.setFullYear(newYear);
            from.setMonth(newMonth - 1);
            from.setDate((lastDayCount - newFirstWeek));
            newFirstWeek += 1;
        }
        var newLastWeek = 0;
        if (lastWeekDay === 7) {
            to = new Date(workDate.getFullYear(), workDate.getMonth(), workDate.getDate());
        }
        while (lastWeekDay < 7) {
            lastWeekDay += 1;
            var newMonth = month;
            var newYear = workDate.getFullYear();
            if (month === 12) {
                newMonth = 1;
                newYear += 1;
            }
            else {
                newMonth += 1;
            }
            nextMonth.push({
                month: newMonth,
                day: 1 + newLastWeek,
                date: new Date(newYear, newMonth - 1, 1 + newLastWeek),
                year: newYear,
                weekday: lastWeekDay,
                actualDay: false,
                actualMonth: false,
                published: false,
            });
            to = new Date(newYear, newMonth - 1, 1 + newLastWeek);
            newLastWeek += 1;
        }
        lastMonth = lastMonth.reverse();
        var completeCalendar = [];
        lastMonth.map(function (last) {
            completeCalendar.push(last);
        });
        actualMonth.map(function (actual) {
            completeCalendar.push(actual);
        });
        nextMonth.map(function (next) {
            completeCalendar.push(next);
        });
        var weeks = [];
        var week = { days: [] };
        var counter = 0;
        completeCalendar.map(function (elem) {
            if (counter !== 0 && counter % 7 === 0) {
                weeks.push(week);
                week = { days: [] };
            }
            counter += 1;
            week.days.push(elem);
        });
        weeks.push(week);
        from.setHours(0);
        from.setMinutes(0);
        from.setSeconds(0);
        to.setHours(23);
        to.setMinutes(59);
        to.setSeconds(59);
        setValue(weeks);
        changeDataParams && changeDataParams({ from: from, to: to });
    };
    return [value, handleChange];
};
exports.default = useDisplayCalendar;
