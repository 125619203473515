{
    "networkContentCommon": {
        "delete": "Löschen",
        "cancel": "Abbrechen"
    },
    "networkContentMessages": {
        "loadFacebookFeedsSuccess": "Alle Facebook Beiträge wurden erfolgreich geladen.",
        "loadFacebookFeedsError": "Facebook Beiträge konnten nicht geladen werden.",
        "deletePostError": "Der Facebook Beitrag konnte nicht gelöscht werden.",
        "writePostCommentError": "Der Kommentar konnte nicht gespeichert werden.",
        "unlikePostError": "Die Aktion konnte nicht ausgeführt werden.",
        "unlikePostCommentError": "Die Aktion konnte nicht ausgeführt werden.",
        "likePostError": "Die Aktion konnte nicht ausgeführt werden.",
        "likePostCommentError": "Die Aktion konnte nicht ausgeführt werden.",
        "deleteWarningTitle": "Achtung!",
        "deletePostWarning": "Wollen Sie den Beitrag wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
        "deleteCommentWarning": "Wollen Sie den Kommentar wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
        "noPostsYet": "Noch keine Beiträge."
    },
    "networkContentDashboard": {
        "published": "veröffentlicht am:",
        "commentPreview": "Schreibe einen Kommentar",
        "deletePost": "Beitrag löschen",
        "deleteComment": "Kommentar löschen",
        "likePost": "Gefällt mir",
        "unlikePost": "Gefällt mir nicht mehr",
        "likeComment": "Gefällt mir",
        "unlikeComment": "Gefällt mir nicht mehr",
        "postAgain": "Erneut veröffentlichen",
        "postDetails": "Beitragsdetails"
    }
}
