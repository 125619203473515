"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDirtyValues = void 0;
function getDirtyValues(dirtyFields, values) {
    return Object.keys(dirtyFields).reduce(function (prev, key) {
        var _a;
        // Unsure when RFH sets this to `false`, but omit the field if so.
        if (!dirtyFields[key])
            return prev;
        return __assign(__assign({}, prev), (_a = {}, _a[key] = typeof dirtyFields[key] === 'object'
            ? getDirtyValues(dirtyFields[key], values[key])
            : values[key], _a));
    }, {});
}
exports.getDirtyValues = getDirtyValues;
