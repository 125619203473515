"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var clearTemplate = function (posting) {
    var _a, _b, _c;
    var clearedTemplate = __assign({}, posting);
    var newContent = { text: clearedTemplate.content.text, extensions: {} };
    if ((_a = clearedTemplate.content.image) === null || _a === void 0 ? void 0 : _a.url) {
        newContent.image = clearedTemplate.content.image;
    }
    if ((_b = clearedTemplate.content.video) === null || _b === void 0 ? void 0 : _b.url) {
        newContent.video = clearedTemplate.content.video;
    }
    if ((_c = clearedTemplate.content.link) === null || _c === void 0 ? void 0 : _c.url) {
        newContent.link = clearedTemplate.content.link;
    }
    clearedTemplate.content = newContent;
    return clearedTemplate;
};
exports.default = clearTemplate;
