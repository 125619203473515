{
    "statisticsCommon": {
        "whereAreYou": "Du bist hier",
        "dailyReach": "tägliche Reichweite",
        "reachOrganic": "organisatorische Reichweite",
        "reachPaid": "bezahlte Reichweite",
        "pageCalls": "Seitenaufrufe",
        "organicReach": "organische Reichweite in 24h",
        "paidReach": "bezahlte Reichweite in 24h",
        "fans": "Anzahl Fans",
        "talkingAboutCount": "im Gespräch",
        "pageParameters": "Parameter:",
        "statistics": "Statistiken",
        "dateRange": "Zeitbereich",
        "dateRangeFrom": "Zeitbereich von",
        "dateRangeTo": "Zeitbereich bis",
        "successfulPosts": "Erfolgreichste Beiträge",
        "unsuccessfulPosts": "Erfolglose Beiträge",
        "engagementRate": "Engagement-Rate",
        "reachedFansPercent": "Relative Reichweite",
        "sort": "Sortierung",
        "posting": "Posting Statistik",
        "individual": "individuell",
        "last7Days": "letzte 7 Tage",
        "last30Days": "letzte 30 Tage",
        "pageStatistics": "Seiten Statistik",
        "postingStatistics": "Posting Statistik",
        "facebook": "Facebook Statistik",
        "instagram": "Instagram Statistik",
        "facebookNetwork": "Facebook",
        "instagramNetwork": "Instagram",
        "network": "Netzwerk",
        "mediaCount": "Beiträge",
        "followersCount": "Follower",
        "followsCount": "Folge ich",
        "emailContacts": "Kontakt E-Mail",
        "websiteClicks": "Kontakt Website",
        "profileViews": "Profilaufrufe",
        "noDataYet": "Es sind keine Daten in dem Zeitbereich vorhanden.",
        "likes": "Likes",
        "comments": "Kommentare",
        "shares": "Shares",
        "impressions": "Impressionen",
        "impressionsOrganic": "Impressionen organisch",
        "impressionsPaid": "Impressionen bezahlt",
        "views": "Impressionen",
        "reach": "Erreichte Personen",
        "mainStat": "Kumulierte Posting-Statistiken",
        "singleStat": "Einzelne Postings",
        "editorialPublishedPostings": "Redaktionell veröffentlichte Postings",
        "actuallyPublishedPostings": "Tatsächlich veröffentlichte Postings auf allen Seiten",
        "textPostingCount": "Text-Posting",
        "imagePostingCount": "Bild-Posting",
        "linkPostingCount": "Link-Posting",
        "videoPostingCount": "Video-Posting",
        "PHOTO": "Bilder-Posting",
        "MESSAGE": "Status-Posting",
        "LINK": "Link-Posting",
        "VIDEO": "Video-Posting"
    }
}
