"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.saveCSVFromBlob = void 0;
var date_fns_1 = require("date-fns");
function saveCSVFromBlob(blob, dateFrom, dateTo) {
    var dateFormat = 'yyyy-MM-dd';
    var filename = "statistic_summary_export_".concat((0, date_fns_1.format)(dateFrom, dateFormat).toString(), "_").concat((0, date_fns_1.format)(dateTo, dateFormat).toString(), ".csv");
    var content = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), blob], {
        type: 'text/csv;encoding:utf-8',
    });
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE doesn't allow using a blob object directly as link href.
        // Workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(content, filename);
        return;
    }
    var blobURL = window.URL.createObjectURL(content);
    var tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobURL);
    }, 100);
}
exports.saveCSVFromBlob = saveCSVFromBlob;
