"use strict";
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.helpSagas = void 0;
var effects_1 = require("redux-saga/effects");
var typescript_fsa_redux_saga_1 = require("typescript-fsa-redux-saga");
var help_actions_1 = require("./help.actions");
var instances_1 = require("../../api/instances");
var common_actions_1 = require("../common/common.actions");
// getHelpPost
var getHelpPostWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(help_actions_1.getHelpPost)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.HelpROApi, instances_1.HelpROApi.getHelpPost], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchGetHelpPostInitiated() {
    var action, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(help_actions_1.getHelpPostInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, getHelpPostWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_1 = _a.sent();
                console.error('error in watchGetHelpPostInitiated: ', e_1);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// getHelpPostList
var getHelpPostListWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(help_actions_1.getHelpPostList)(function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.HelpROApi, instances_1.HelpROApi.getHelpPostList])];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchGetHelpPostListInitiated() {
    var action, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(help_actions_1.getHelpPostListInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, getHelpPostListWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_2 = _a.sent();
                console.error('error in watchGetHelpPostListInitiated: ', e_2);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// createEditorHelpPost
var createEditorHelpPostWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(help_actions_1.createEditorHelpPost)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.EditorHelpRESTServiceApi, instances_1.EditorHelpRESTServiceApi.createEditorHelpPost], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchCreateEditorHelpPostInitiated() {
    var action, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 9];
                return [4 /*yield*/, (0, effects_1.take)(help_actions_1.createEditorHelpPostInitiated)];
            case 1:
                action = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestStarted)({ type: action.type }))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                _a.trys.push([3, 6, , 8]);
                return [4 /*yield*/, createEditorHelpPostWorker(action.payload)];
            case 4:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFinished)({ type: action.type }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 8];
            case 6:
                e_3 = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFailed)({ type: action.type }))];
            case 7:
                _a.sent();
                console.error('error in watchCreateEditorHelpPostInitiated: ', e_3);
                return [3 /*break*/, 8];
            case 8: return [3 /*break*/, 0];
            case 9: return [2 /*return*/];
        }
    });
}
// deleteEditorHelpPost
var deleteEditorHelpPostWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(help_actions_1.deleteEditorHelpPost)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.EditorHelpRESTServiceApi, instances_1.EditorHelpRESTServiceApi.deleteEditorHelpPost], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchDeleteEditorHelpPostInitiated() {
    var action, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(help_actions_1.deleteEditorHelpPostInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, deleteEditorHelpPostWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_4 = _a.sent();
                console.error('error in watchDeleteEditorHelpPostInitiated: ', e_4);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// getEditorHelpPost
var getEditorHelpPostWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(help_actions_1.getEditorHelpPost)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.EditorHelpRESTServiceApi, instances_1.EditorHelpRESTServiceApi.getEditorHelpPost], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchGetEditorHelpPostInitiated() {
    var action, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.take)(help_actions_1.getEditorHelpPostInitiated)];
            case 1:
                action = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, getEditorHelpPostWorker(action.payload)];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_5 = _a.sent();
                console.error('error in watchGetHelpPostInitiated: ', e_5);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// updateEditorHelpPost
var updateEditorHelpPostWorker = (0, typescript_fsa_redux_saga_1.bindAsyncAction)(help_actions_1.updateEditorHelpPost)(function (params) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.call)([instances_1.EditorHelpRESTServiceApi, instances_1.EditorHelpRESTServiceApi.updateEditorHelpPost], params)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
});
function watchUpdateEditorHelpPostInitiated() {
    var action, e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 9];
                return [4 /*yield*/, (0, effects_1.take)(help_actions_1.updateEditorHelpPostInitiated)];
            case 1:
                action = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestStarted)({ type: action.type }))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                _a.trys.push([3, 6, , 8]);
                return [4 /*yield*/, updateEditorHelpPostWorker(action.payload)];
            case 4:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFinished)({ type: action.type }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 8];
            case 6:
                e_6 = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, common_actions_1.requestFailed)({ type: action.type }))];
            case 7:
                _a.sent();
                console.error('error in watchUpdateHelpPostInitiated: ', e_6);
                return [3 /*break*/, 8];
            case 8: return [3 /*break*/, 0];
            case 9: return [2 /*return*/];
        }
    });
}
function helpSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.fork)(watchGetHelpPostInitiated)];
            case 1:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchGetHelpPostListInitiated)];
            case 2:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchCreateEditorHelpPostInitiated)];
            case 3:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchDeleteEditorHelpPostInitiated)];
            case 4:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchGetEditorHelpPostInitiated)];
            case 5:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.fork)(watchUpdateEditorHelpPostInitiated)];
            case 6:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.helpSagas = helpSagas;
