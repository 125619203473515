"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var types_1 = require("../shared/types/types");
var sm_publish_frontend_api_1 = require("@3m5/sm_publish-frontend-api");
var fileSize = function (imageBaseString) {
    var suffix = 1; // y will be 2 if Base64 ends with '==' and 1 if Base64 ends with '='.
    var imageSize = imageBaseString.length;
    // /8bit > byte
    return Math.floor((imageSize * (3 / 4) / 8) - suffix);
};
var checkFileSize = function (img, width, height, socialMediaNetwork, restrictions) {
    var fileSizeCalc = fileSize(img);
    var error = [];
    // todo video noch prüfen
    Object.keys(socialMediaNetwork).map(function (network) {
        var _a;
        var networkType = sm_publish_frontend_api_1.NetworkType.Facebook;
        if (network === 'instagram') {
            networkType = sm_publish_frontend_api_1.NetworkType.Instagram;
        }
        if (network === 'googleMyBusiness') {
            networkType = sm_publish_frontend_api_1.NetworkType.GoogleMyBusiness;
        }
        if (socialMediaNetwork[network] && restrictions[network]) {
            // filesize restrictions[network].image.maxFileSizeInBytes
            var maxFileSizeInBytes = (_a = restrictions[network].image) === null || _a === void 0 ? void 0 : _a.maxFileSizeInBytes;
            if (maxFileSizeInBytes && fileSizeCalc >= maxFileSizeInBytes) {
                error.push({
                    errorCode: types_1.PostingErrorCode.errorImageTooLarge,
                    network: networkType,
                });
            }
        }
    });
    return error;
};
exports.default = checkFileSize;
