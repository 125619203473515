"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.googleMyBusinessReducer = void 0;
var typescript_fsa_reducers_1 = require("typescript-fsa-reducers");
var immer_1 = require("immer");
var googleMyBusiness_actions_1 = require("./googleMyBusiness.actions");
var INITIAL_STATE = {
    fetching: false,
    error: {},
};
exports.googleMyBusinessReducer = (0, typescript_fsa_reducers_1.reducerWithInitialState)(INITIAL_STATE)
    /**
     * getGooglePageFeed
     */
    .case(googleMyBusiness_actions_1.getGooglePageFeed.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getGooglePageFeed;
    });
})
    .case(googleMyBusiness_actions_1.getGooglePageFeed.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.googleFeeds = result;
        draft.fetching = false;
    });
})
    .case(googleMyBusiness_actions_1.getGooglePageFeed.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getGooglePageFeed = error;
    });
});
