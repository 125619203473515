"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useGalleryWidth = function (ref, trigger, elemSize, items) {
    if (elemSize === void 0) { elemSize = 110; }
    if (items === void 0) { items = 20; }
    var _a = (0, react_1.useState)(items), itemsPerPage = _a[0], setItemsPerPage = _a[1];
    var _b = (0, react_1.useState)(elemSize), elementSize = _b[0], setElementSize = _b[1];
    (0, react_1.useEffect)(function () {
        var handleResize = function () {
            if (ref.current) {
                var containerWidth = ref.current.offsetWidth;
                var elemProRow = Math.floor(containerWidth / elementSize);
                var remainingSpace = containerWidth - (elemProRow * elementSize);
                setItemsPerPage(elemProRow * 2); // two rows
                setElementSize(remainingSpace / elemProRow + elementSize - 5.2); // minus 5px margin + 0.2 interference leveled
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, [ref, trigger]);
    return { itemsPerPage: itemsPerPage, elementSize: elementSize };
};
exports.default = useGalleryWidth;
