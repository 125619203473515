"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageStatTypes = exports.PublishedTemplatePostTypes = exports.PostingTypes = void 0;
var PostingTypes;
(function (PostingTypes) {
    PostingTypes["likes"] = "likes";
    PostingTypes["comments"] = "comments";
    PostingTypes["shares"] = "shares";
    PostingTypes["impressions"] = "impressions";
    PostingTypes["reach"] = "reach";
})(PostingTypes = exports.PostingTypes || (exports.PostingTypes = {}));
var PublishedTemplatePostTypes;
(function (PublishedTemplatePostTypes) {
    PublishedTemplatePostTypes["likes"] = "likes";
    PublishedTemplatePostTypes["comments"] = "comments";
    PublishedTemplatePostTypes["shares"] = "shares";
    PublishedTemplatePostTypes["views"] = "views";
    PublishedTemplatePostTypes["impressionsOrganic"] = "impressionsOrganic";
    PublishedTemplatePostTypes["impressionsPaid"] = "impressionsPaid";
})(PublishedTemplatePostTypes = exports.PublishedTemplatePostTypes || (exports.PublishedTemplatePostTypes = {}));
var PageStatTypes;
(function (PageStatTypes) {
    PageStatTypes["fans"] = "fans";
    PageStatTypes["dailyReach"] = "dailyReach";
    PageStatTypes["talkingAboutCount"] = "talkingAboutCount";
    PageStatTypes["pageCalls"] = "pageCalls";
    PageStatTypes["organicReach"] = "organicReach";
    PageStatTypes["paidReach"] = "paidReach";
    PageStatTypes["mediaCount"] = "mediaCount";
    PageStatTypes["followersCount"] = "followersCount";
    PageStatTypes["followsCount"] = "followsCount";
    PageStatTypes["emailContacts"] = "emailContacts";
    PageStatTypes["websiteClicks"] = "websiteClicks";
    PageStatTypes["profileViews"] = "profileViews";
})(PageStatTypes = exports.PageStatTypes || (exports.PageStatTypes = {}));
