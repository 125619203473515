"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.coreRootReducer = void 0;
var redux_1 = require("redux");
var common_reducer_1 = require("./common/common.reducer");
var dashboard_reducer_1 = require("./dashboard/dashboard.reducer");
var help_reducer_1 = require("./help/help.reducer");
var images_reducer_1 = require("./images/images.reducer");
exports.coreRootReducer = (0, redux_1.combineReducers)({
    commonStore: common_reducer_1.CommonReducer,
    dashboardStore: dashboard_reducer_1.DashboardReducer,
    imagesStore: images_reducer_1.ImagesReducer,
    helpStore: help_reducer_1.HelpReducer,
});
