"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatisticsUserReducer = void 0;
var typescript_fsa_reducers_1 = require("typescript-fsa-reducers");
var immer_1 = require("immer");
var statistics_user_actions_1 = require("./statistics.user.actions");
var INITIAL_STATE = {
    fetching: false,
    error: {},
};
exports.StatisticsUserReducer = (0, typescript_fsa_reducers_1.reducerWithInitialState)(INITIAL_STATE)
    /**
     * getFacebookPageStatistics
     */
    .case(statistics_user_actions_1.getFacebookPageStatistics.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getFacebookPageStatistics;
    });
})
    .case(statistics_user_actions_1.getFacebookPageStatistics.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.facebookPageStatistics = result;
        draft.fetching = false;
    });
})
    .case(statistics_user_actions_1.getFacebookPageStatistics.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getFacebookPageStatistics = error;
    });
})
    /**
     * getInstagramPageStatistics
     */
    .case(statistics_user_actions_1.getInstagramPageStatistics.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getInstagramPageStatistics;
    });
})
    .case(statistics_user_actions_1.getInstagramPageStatistics.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.instagramPageStatistics = result;
        draft.fetching = false;
    });
})
    .case(statistics_user_actions_1.getInstagramPageStatistics.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getInstagramPageStatistics = error;
    });
})
    /**
     * getFacebookPostsInRangeForPage
     */
    .case(statistics_user_actions_1.getFacebookPostsInRangeForPage.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getFacebookPostsInRangeForPage;
    });
})
    .case(statistics_user_actions_1.getFacebookPostsInRangeForPage.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.facebookPostsInRange = result;
        draft.fetching = false;
    });
})
    .case(statistics_user_actions_1.getFacebookPostsInRangeForPage.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getFacebookPostsInRangeForPage = error;
    });
})
    /**
     * getInstagramPostsInRangeForPage
     */
    .case(statistics_user_actions_1.getInstagramPostsInRangeForPage.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getInstagramPostsInRangeForPage;
    });
})
    .case(statistics_user_actions_1.getInstagramPostsInRangeForPage.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.instagramPostsInRange = result;
        draft.fetching = false;
    });
})
    .case(statistics_user_actions_1.getInstagramPostsInRangeForPage.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getInstagramPostsInRangeForPage = error;
    });
});
