"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.facebookReducer = void 0;
var typescript_fsa_reducers_1 = require("typescript-fsa-reducers");
var immer_1 = require("immer");
var facebook_actions_1 = require("./facebook.actions");
var INITIAL_STATE = {
    fetching: false,
    updateImprintAutomatically: false,
    error: {},
};
exports.facebookReducer = (0, typescript_fsa_reducers_1.reducerWithInitialState)(INITIAL_STATE)
    /**
       * getFacebookPage
       */
    .case(facebook_actions_1.getFacebookPage.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getFacebookPage;
    });
})
    .case(facebook_actions_1.getFacebookPage.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.facebookPage = result;
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.getFacebookPage.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getFacebookPage = error;
    });
})
    /**
       * saveFacebookPageInformation
       */
    .case(facebook_actions_1.saveFacebookPageInformation.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.saveFacebookPageInformation;
    });
})
    .case(facebook_actions_1.saveFacebookPageInformation.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.saveFacebookPageInformation.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.saveFacebookPageInformation = error;
    });
})
    /**
       * getPotentialPageImprint
       */
    .case(facebook_actions_1.getPotentialPageImprint.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getPotentialPageImprint;
    });
})
    .case(facebook_actions_1.getPotentialPageImprint.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.potentialImprint = result;
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.getPotentialPageImprint.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getPotentialPageImprint = error;
    });
})
    /**
       * getAllFacebookCategories
       */
    .case(facebook_actions_1.getAllFacebookCategories.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.getAllFacebookCategories;
    });
})
    .case(facebook_actions_1.getAllFacebookCategories.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.categories = result.categories;
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.getAllFacebookCategories.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.getAllFacebookCategories = error;
    });
})
    /**
       * uploadMediaFile
       */
    .case(facebook_actions_1.uploadMediaFile.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.uploadMediaFile;
    });
})
    .case(facebook_actions_1.uploadMediaFile.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.mediaFileMetadata = result;
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.uploadMediaFile.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.uploadMediaFile = error;
    });
})
    /**
       * updateCoverImage
       */
    .case(facebook_actions_1.updateCoverImage.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.uploadMediaFile;
    });
})
    .case(facebook_actions_1.updateCoverImage.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.updateCoverImage.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.uploadMediaFile = error;
    });
})
    /**
       * updateProfileImage
       */
    .case(facebook_actions_1.updateProfileImage.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.uploadMediaFile;
    });
})
    .case(facebook_actions_1.updateProfileImage.done, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.updateProfileImage.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.uploadMediaFile = error;
    });
})
    /**
       * listMediaFiles
       */
    .case(facebook_actions_1.listMediaFiles.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.listMediaFiles;
    });
})
    .case(facebook_actions_1.listMediaFiles.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.mediaFiles = result;
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.listMediaFiles.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.listMediaFiles = error;
    });
})
    /**
       * listCoverImages
       */
    .case(facebook_actions_1.listCoverImages.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.listCoverImages;
    });
})
    .case(facebook_actions_1.listCoverImages.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.coverImages = result;
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.listCoverImages.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.listCoverImages = error;
    });
})
    /**
       * listProfileImages
       */
    .case(facebook_actions_1.listProfileImages.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.listProfileImages;
    });
})
    .case(facebook_actions_1.listProfileImages.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.profileImages = result;
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.listProfileImages.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.listProfileImages = error;
    });
})
    /**
       * uploadProfileMediaFile
       */
    .case(facebook_actions_1.uploadProfileMediaFile.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.uploadTemporaryMediaFile;
    });
})
    .case(facebook_actions_1.uploadProfileMediaFile.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.profileMediaFile = result;
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.uploadProfileMediaFile.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.uploadTemporaryMediaFile = error;
    });
})
    /**
       * uploadCoverMediaFile
       */
    .case(facebook_actions_1.uploadCoverMediaFile.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.uploadTemporaryMediaFile;
    });
})
    .case(facebook_actions_1.uploadCoverMediaFile.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.coverMediaFile = result;
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.uploadCoverMediaFile.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.uploadTemporaryMediaFile = error;
    });
})
    /**
       * getUpdateImprintAutomaticallyForCurrentUser
       */
    .case(facebook_actions_1.getUpdateImprintAutomaticallyForCurrentUser.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.updateImprintAutomatically = result.active;
    });
});
