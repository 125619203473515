"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var material_1 = require("@mui/material");
var styles_1 = require("@mui/styles");
var useStyles = (0, styles_1.makeStyles)(function (theme) { return ({
    root: {
        width: '100%',
        minWidth: '200px',
    },
}); });
var SelectTagComponent = function (props) {
    var classes = useStyles();
    var _a = (0, react_1.useState)(props.value || []), value = _a[0], setValue = _a[1];
    var _b = (0, react_1.useState)(false), selectActive = _b[0], setSelectActive = _b[1];
    (0, react_1.useEffect)(function () {
        props.value && setValue(props.value);
    }, [props.value]);
    var filter = (0, material_1.createFilterOptions)();
    var checkTags = function (tags) {
        var cleanedTags = [];
        tags.forEach(function (tag) {
            if (typeof tag !== 'string') {
                if (!(tag.id && (tag.id).includes('newTag_')) || !tag.id) {
                    if (!cleanedTags.find(function (elem) { return elem.label === tag.label; })) {
                        cleanedTags.push(tag);
                    }
                }
                else {
                    var tagLabel = tag.id.replace('newTag_', '').replace('"', '');
                    if (!cleanedTags.find(function (elem) { return elem.label === tag.label; })) {
                        cleanedTags.push({ label: tagLabel });
                    }
                }
            }
        });
        return cleanedTags;
    };
    return (react_1.default.createElement(material_1.Autocomplete, { multiple: true, value: value, id: props.id, selectOnFocus: true, clearOnBlur: true, className: classes.root, disableClearable: props.disableClearable ? props.disableClearable : false, disabled: props.disabled ? props.disabled : false, fullWidth: props.fullWidth, size: props.size, limitTags: props.limitTags, options: props.options, defaultValue: props.defaultValue, freeSolo: true, handleHomeEndKeys: true, onChange: function (event, elem) {
            setValue(checkTags(elem));
            props.onChange && props.onChange(checkTags(elem));
        }, filterOptions: function (options, params) {
            var filtered = filter(options, params);
            var inputValue = params.inputValue;
            // Suggest the creation of a new value
            var isExisting = options.some(function (option) { return inputValue === option.label; });
            if (inputValue !== '' && !isExisting) {
                filtered.push({
                    id: "newTag_".concat(inputValue),
                    label: "\"".concat(inputValue, "\" hinzuf\u00FCgen"),
                });
            }
            return filtered;
        }, renderTags: function (value, getTagProps) {
            return value.map(function (option, index) {
                var tagLabel = option.label;
                if (option.id && (option.id).includes('newTag_')) {
                    tagLabel = option.id.replace('newTag_', '').replace('"', '');
                }
                return (react_1.default.createElement("div", { key: index },
                    react_1.default.createElement(material_1.Chip, __assign({ variant: 'filled', label: tagLabel }, getTagProps({ index: index })))));
            });
        }, renderInput: function (params) { return (react_1.default.createElement(material_1.TextField, __assign({}, params, { label: props.label, name: props.name, placeholder: props.placeholder, required: props.required, helperText: props.helperText, error: props.error, InputLabelProps: {
                shrink: true,
            }, variant: props.variant, margin: props.margin, onKeyDown: function (event) {
                if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
                    setSelectActive(true);
                }
                if (event.code === 'Enter' && selectActive) {
                    setSelectActive(false);
                }
                if (event.code === 'Enter' && event.target && !selectActive) {
                    var target_1 = event.target;
                    var newValues = value;
                    var equalToExistingTag = props.options.find(function (Tag) { return Tag.label.toLowerCase() === target_1.value.toLowerCase(); });
                    if (equalToExistingTag) {
                        newValues.push(equalToExistingTag);
                    }
                    else {
                        newValues.push({
                            label: target_1.value,
                        });
                    }
                    setValue(newValues);
                }
            } }))); } }));
};
exports.default = SelectTagComponent;
