"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.accountRootReducer = void 0;
var redux_1 = require("redux");
var common_reducer_1 = require("./common/common.reducer");
var accounts_reducer_1 = require("./accounts/accounts.reducer");
exports.accountRootReducer = (0, redux_1.combineReducers)({
    commonStore: common_reducer_1.CommonReducer,
    accountsStore: accounts_reducer_1.AccountsReducer,
});
