"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getDateFormat = function (option) {
    if (option === 'time') {
        return 'HH:mm';
    }
    if (option === 'dateTime') {
        return 'dd.MM.yyyy HH:mm';
    }
    return 'dd.MM.yyyy';
};
exports.default = getDateFormat;
