"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.facebookReducer = void 0;
var typescript_fsa_reducers_1 = require("typescript-fsa-reducers");
var immer_1 = require("immer");
var facebook_actions_1 = require("./facebook.actions");
var INITIAL_STATE = {
    fetching: false,
    error: {},
};
exports.facebookReducer = (0, typescript_fsa_reducers_1.reducerWithInitialState)(INITIAL_STATE)
    /**
     * getFacebookFeedForPage
     */
    .case(facebook_actions_1.getFacebookFeedForPage.started, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = true;
        delete draft.error.loadFacebookFeedForPage;
    });
})
    .case(facebook_actions_1.getFacebookFeedForPage.done, function (state, _a) {
    var result = _a.result;
    return (0, immer_1.produce)(state, function (draft) {
        draft.facebookFeeds = result;
        draft.fetching = false;
    });
})
    .case(facebook_actions_1.getFacebookFeedForPage.failed, function (state, _a) {
    var error = _a.error;
    return (0, immer_1.produce)(state, function (draft) {
        draft.fetching = false;
        draft.error.loadFacebookFeedForPage = error;
    });
});
