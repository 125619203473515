"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHeaders = void 0;
var getHeaders = function (xsrfToken, contentType) {
    if (contentType === void 0) { contentType = 'application/json'; }
    var requestHeaders = new Headers();
    requestHeaders.set('Accept', 'application/json');
    requestHeaders.set('Content-Type', contentType);
    if (xsrfToken)
        requestHeaders.set('X-XSRF-TOKEN', xsrfToken);
    return requestHeaders;
};
exports.getHeaders = getHeaders;
