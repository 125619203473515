"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFBId = exports.getYTId = exports.matchSocialNetworkUrl = void 0;
function matchSocialNetworkUrl(url) {
    if (url) {
        var regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        if (url.match(regExp)) {
            return true;
        }
    }
    return false;
}
exports.matchSocialNetworkUrl = matchSocialNetworkUrl;
function getYTId(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    var match = url.match(regExp);
    return (match && match[2].length === 11)
        ? match[2]
        : null;
}
exports.getYTId = getYTId;
function getFBId(url) {
    var regExp = /^.*(?:https?:\/\/)?(?:www\.)?facebook\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w-]*\/)*([\w\-.]*).*/;
    var match = url.match(regExp);
    var id = match && match[1].split('_');
    return id && id[1];
}
exports.getFBId = getFBId;
