"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTimeAsString = void 0;
function getTimeAsString(date) {
    if (date) {
        return "".concat(new Date(date).getHours().toString().replace(/^(\d)$/, '0$1'), ":").concat(new Date(date).getUTCMinutes().toString().replace(/^(\d)$/, '0$1'));
    }
    else
        return '';
}
exports.getTimeAsString = getTimeAsString;
