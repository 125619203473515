"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostingErrorCode = exports.TemplateSorting = exports.PostingScreen = exports.PostingType = exports.FilterType = exports.PostSource = exports.PostingStatus = void 0;
var PostingStatus;
(function (PostingStatus) {
    PostingStatus["success"] = "success";
    PostingStatus["error"] = "error";
    PostingStatus["pending"] = "pending";
})(PostingStatus = exports.PostingStatus || (exports.PostingStatus = {}));
var PostSource;
(function (PostSource) {
    PostSource["IndividualPost"] = "IndividualPost";
    PostSource["AutomaticAbo"] = "AutomaticAbo";
    PostSource["ManualAbo"] = "ManualAbo";
    PostSource["Unknown"] = "Unknown";
})(PostSource = exports.PostSource || (exports.PostSource = {}));
var FilterType;
(function (FilterType) {
    FilterType["template"] = "template";
    FilterType["topic"] = "topic";
})(FilterType = exports.FilterType || (exports.FilterType = {}));
var PostingType;
(function (PostingType) {
    PostingType["template"] = "template";
    PostingType["posting"] = "posting";
})(PostingType = exports.PostingType || (exports.PostingType = {}));
var PostingScreen;
(function (PostingScreen) {
    PostingScreen["MAIN"] = "MAIN";
    PostingScreen["MEDIA"] = "MEDIA";
    PostingScreen["EMOTION"] = "EMOTION";
    PostingScreen["LOCATION"] = "LOCATION";
    PostingScreen["TEMPLATE"] = "TEMPLATE";
    PostingScreen["TAGS"] = "TAGS";
})(PostingScreen = exports.PostingScreen || (exports.PostingScreen = {}));
var TemplateSorting;
(function (TemplateSorting) {
    TemplateSorting["new"] = "new";
    TemplateSorting["popular"] = "popular";
})(TemplateSorting = exports.TemplateSorting || (exports.TemplateSorting = {}));
var PostingErrorCode;
(function (PostingErrorCode) {
    PostingErrorCode["errorImageTooLarge"] = "errorImageTooLarge";
    PostingErrorCode["errorImageRatioWrong"] = "errorImageRatioWrong";
    PostingErrorCode["errorVideoTooLarge"] = "errorVideoTooLarge";
    PostingErrorCode["errorVideoRatioWrong"] = "errorVideoRatioWrong";
    PostingErrorCode["errorMessageTooLong"] = "errorMessageTooLong";
    PostingErrorCode["errorNoNetwork"] = "errorNoNetwork";
    PostingErrorCode["errorNoImage"] = "errorNoImage";
    PostingErrorCode["errorNoMessage"] = "errorNoMessage";
    PostingErrorCode["errorNoTopic"] = "errorNoTopic";
})(PostingErrorCode = exports.PostingErrorCode || (exports.PostingErrorCode = {}));
