{
    "postingCommon": {
        "deleteTemplate": "Vorlage löschen",
        "delete": "Löschen",
        "confirm": "Ok",
        "template": "Vorlage",
        "save": "Speichern",
        "cancel": "Abbrechen",
        "description": "Beschreibung",
        "desktopPreview": "Vorschau Desktop",
        "mobilePreview": "Vorschau Mobil",
        "videoImage": "Bild/Video verwenden",
        "image": "Bild verwenden",
        "emotion": "Gefühl/Aktivität",
        "includeTags": "Tags hinzufügen",
        "place": "Standort",
        "chooseTemplate": "Beitragsvorlage wählen",
        "chooseTemplateEditor": "Beitragsvorlage ändern",
        "isAvailableAt": "Veröffentlichen auf:",
        "provideFor": "Bereitstellen für:",
        "share": "Teilen",
        "showCommentPreview": "Kommentieren",
        "like": "Gefällt mir",
        "commentPreview": "Kommentieren ...",
        "updateEditor": "Vorlage anpassen",
        "updateUser": "Beitrag anpassen",
        "createEditor": "Vorlage anlegen",
        "createUser": "Beitrag jetzt veröffentlichen",
        "createUserTime": "Beitrag am {{date}} veröffentlichen",
        "disable": "Abbrechen",
        "preview": "Vorschau",
        "useImage": "Bild verwenden",
        "useFile": "Datei verwenden",
        "uploadImageHint": "Laden Sie ein neues Bild/Video hoch oder wählen sie ein Bild/Video aus der Mediendatenbank.",
        "getCroppedImage": "Bereich auswählen",
        "sortingOption": "Sortieren nach",
        "sortNewFirst": "neueste Beiträge zuerst",
        "sortPopularFirst": "beliebteste Beiträge zuerst",
        "deleteImage": "Bild entfernen",
        "deleteMediaFile": "Entfernen",
        "tags": "Tags",
        "tagsCreate": "Entsprechende Tags",
        "instagramPosts": "Beiträge",
        "instagramPost": "Beitrag",
        "post": "Beitrag",
        "takeTemplate": "Vorlage verwenden",
        "acceptTemplate": "Posting freigeben",
        "editTemplate": "Vorlage bearbeiten",
        "anyCategory": "beliebige Kategorien",
        "anyTopic": "beliebige Themenkanäle",
        "backToPosting": "Zurück zum Beitrag",
        "category": "Kategorie",
        "aboTopic": "Themenkanal",
        "choose": "Bitte wählen",
        "topic": "Themenkanal",
        "topics": "Themenkanäle",
        "planThePost": "Posting planen",
        "manipulateLinks": "Linkindividualisierung aktivieren",
        "addHashtag": "Individuelle Hashtags setzen",
        "postingType": "Postingtyp",
        "templatePosting": "Postingvorlage",
        "topicPosting": "Posting",
        "postAt": "Am",
        "postStart": "Von",
        "postEnd": "Bis",
        "noMediaData": "Noch keine Dateien vorhanden",
        "noPostingData": "Noch keine Postings vorhanden",
        "noPostings": "Keine Postings vorhanden",
        "saveNewPost": "Wollen Sie den Beitrag wirklich veröffentlichen?",
        "updateTemplate": "Wollen Sie die Vorlage wirklich aktualisieren?",
        "publish": "Vorlage veröffentlichen",
        "update": "Vorlage aktualisieren",
        "createNewPost": "Neuen Beitrag erstellen",
        "createNewTemplate": "Neue Vorlage erstellen",
        "editFurther": "Vorlage weiter bearbeiten",
        "editPostFurther": "Beitrag weiter bearbeiten",
        "onlyFailedPostings": "Nur fehlgeschlagene Postings anzeigen",
        "postIsSuccessfullyPublished": "Beitrag wurde erfolgreich veröffentlicht!",
        "fileTooLarge": "Die gewählte Datei ist zu groß! Bitte wählen Sie eine andere Datei. ",
        "fileTooLargeChangeNetwork": "Die gewählte Datei ist zu groß! Wollen Sie ein neues Bild hochladen? Ansonsten wird die Netzwerkauswahl angepasst.",
        "wrongRatio": "Das Seitenverhältnis stimmt nicht mit den Vorgaben des Netzwerkes überein! Bitte passen Sie das Seitenverhältnis an.",
        "wrongRatioNetwork": "Das Seitenverhältnis stimmt nicht mit den Vorgaben des Netzwerkes überein! Wollen Sie das Seitenverhältnis anpassen? Ansonsten wird die Netzwerkauswahl angepasst.",
        "changeRatioButton": "Bildausschnitt anpassen",
        "changeFileButton": "Neue Datei hochladen",
        "unselectNetworkButton": "Netzwerkauswahl anpassen",
        "noTopicsAvailable": "Es sind keine Kategorien verfügbar, bitte legen Sie zuerst Kategorien an.",
        "noTopicsAvailableTitle": "Neuer Beitrag nicht möglich",
        "errorImageTooLarge": "Das gewählte Bild ist für die gewählten Netzwerke zu groß.",
        "errorImageRatioWrong": "Der gewählte Bildausschnitt ist für die gewählten Netzwerke nicht passend.",
        "errorMessageTooLong": "Die Beschreibung ist zu lang.",
        "errorNoImage": "Für die gewählten Netzwerke ist ein Bild erforderlich.",
        "errorNoMessage": "Es wurde keine Beschreibung eingegeben.",
        "errorNoNetwork": "Es muss mindestens ein Netzwerk ausgewählt werden.",
        "errorNoTopic": "Es muss eine Kategorie gewählt werden.",
        "postSourceIndividualPost": "individueller Post",
        "postSourceManualAbo": "manuelles Abo",
        "postSourceAutomaticAbo": "automatisches Abo",
        "postSourceUnknown": "abonnierbare Postings",
        "postSourceTemplate": "Postingvorlage",
        "postStatusAwaitingUserConfirmation": "warten auf Nutzerfreigabe",
        "postStatusErrorWhilePosting": "Posting fehlgeschlagen",
        "postStatusTokenInvalid": "Token ungültig",
        "postStatusScheduled": "Geplant am:",
        "postStatusRetryScheduled": "Wird erneut gepostet",
        "enterMessage": "Geben Sie Ihre Nachricht ein",
        "addButton": "Schaltfläche hinzufügen (optional)",
        "addLink": "Hier Link angeben",
        "newFeed": "Neuen Beitrag erstellen",
        "filterByTags": "Nach Tags filtern",
        "actionButtonUnspecified": "",
        "actionButtonBook": "Buchen",
        "actionButtonOrder": "Bestellen",
        "actionButtonShop": "Kaufen",
        "actionButtonLearnMore": "Weitere Informationen",
        "actionButtonSignUp": "Anmelden",
        "actionButtonCall": "Jetzt anrufen",
        "actionButtonNone": "-",
        "filterTemplate": "alle Postingvorlagen",
        "filterTopic": "alle abonnierbaren Postings",
        "publishToDashboard": "Zurück zum Dashboard",
        "publishToCalender": "Zurück zum Kalendar",
        "statisticViews": "Views",
        "statisticClicks": "Clicks",
        "statisticLikes": "Likes",
        "getTopicsAndMetadataForCurrentUserError": "Die Themenkanäle konnten nicht geladen werden.",
        "getMediaFileMetadataError": "Metadaten für Mediadateien",
        "mediaFile": "Mediadatei",
        "temporaryMediaFile": "Temporäre Mediadatei",
        "mediaFiles": "Mediadateien",
        "chooseDate": "Datum wählen",
        "deleteLink": "Link entfernen"
    },
    "postingMessages": {
        "isSuccessfullyCreated": "{{context}} wurde erfolgreich erstellt!",
        "createError": "{{context}} konnte nicht erstellt werden!",
        "creatingError": "{{context}} konnten nicht erstellt werden!",
        "isSuccessfullyConfirmed": "{{context}} wurde erfolgreich bestätigt!",
        "confirmError": "{{context}} konnte nicht bestätigt werden!",
        "getPostError": "{{context}} wurde bereits bestätigt!",
        "isSuccessfullyDeleted": "{{context}} wurde erfolgreich gelöscht!",
        "deleteError": "{{context}} konnte nicht gelöscht werden!",
        "isSuccessfullyUpdated": "{{context}} wurde erfolgreich aktualisiert!",
        "updateError": "{{context}} konnte nicht aktualisiert werden!",
        "areSuccessfullyUpdated": "{{context}} wurden erfolgreich aktualisiert!",
        "updatingError": "{{context}} konnten nicht aktualisiert werden!",
        "loadingError": "{{context}} konnten nicht geladen werden!",
        "loadError": "{{context}} konnte nicht geladen werden!"
    },
    "postCommon": {
        "delete": "Löschen",
        "retry": "Erneut veröffentlichen",
        "confirm": "Bestätigen",
        "edit": "Bearbeiten",
        "editPost": "Beitrag bearbeiten",
        "commentPreview": "Kommentieren ...",
        "comment": "Kommentar",
        "comments": "Kommentare",
        "like": "Gefällt mir",
        "showCommentPreview": "Kommentieren",
        "share": "Teilen",
        "published": "veröffentlicht am:",
        "available": "verfügbar seit:",
        "availableOn": "verfügbar ab:",
        "availableFromTo": "verfügbar vom {{from}} bis zum {{to}}",
        "created": "erstellt am:",
        "unlike": "Gefällt mir nicht mehr",
        "answer": "Antworten",
        "minute": "Min.",
        "month": "Mon.",
        "hour": "Std.",
        "days": "Tage",
        "day": "Tag",
        "week": "Wo.",
        "years": "Jahre",
        "year": "Jahr",
        "for": "vor",
        "noCommentsYet": "Noch keine Kommentare.",
        "person": "Person",
        "persons": "Personen",
        "liked": "Gefällt",
        "takeTemplate": "Vorlage verwenden",
        "deferredPostStatusScheduled": "Veröffentlichung geplant",
        "deferredPostStatusAwaitingUserConfirmation": "Warten auf Freigabe",
        "deferredPostStatusQueued": "Veröffentlichung geplant",
        "deferredPostStatusProcessing": "Veröffentlichung läuft",
        "deferredPostStatusRetryScheduled": "erneute Veröffentichung geplant",
        "deferredPostStatusTokenInvalid": "Token ungültig",
        "deferredPostStatusErrorWhilePosting": "Fehler beim Posten",
        "actionButtonUnspecified": "",
        "actionButtonBook": "Buchen",
        "actionButtonOrder": "Bestellen",
        "actionButtonShop": "Kaufen",
        "actionButtonLearnMore": "Weitere Informationen",
        "actionButtonSignUp": "Anmelden",
        "actionButtonCall": "Jetzt anrufen",
        "facebookPage": "Facebook Seite",
        "post": "Beitrag",
        "posts": "Beiträge",
        "topic": "Themenkanal",
        "topics": "Themenkanäle",
        "personCount": "Erreichte Personen",
        "impressions": "Impressionen",
        "relativeCoverage": "Relative Reichweite",
        "engagementRate": "Engagement-Rate",
        "shares": "Shares",
        "reactions": "Reactions",
        "likes": "Likes",
        "saved": "Saved",
        "reach": "Reichweite"
    },
    "postMessages": {
        "commentIsSuccessfullyPublished": "Kommentar wurde erfolgreich veröffentlicht!",
        "commentIsSuccessfullyDeleted": "Kommentar wurde erfolgreich gelöscht!",
        "getTopicsAndMetadataForCurrentUserError": "Die Themenkanäle konnten nicht geladen werden.",
        "deleteWarningTitle": "Achtung!",
        "deletePostWarning": "Wollen Sie den Beitrag wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
        "isSuccessfullyCreated": "{{context}} wurde erfolgreich erstellt!",
        "createError": "{{context}} konnte nicht erstellt werden!",
        "isSuccessfullyDeleted": "{{context}} wurde erfolgreich gelöscht!",
        "deleteError": "{{context}} konnte nicht gelöscht werden!",
        "isSuccessfullyUpdated": "{{context}} wurde erfolgreich aktualisiert!",
        "updateError": "{{context}} konnte nicht aktualisiert werden!",
        "areSuccessfullyUpdated": "{{context}} wurden erfolgreich aktualisiert!",
        "updatingError": "{{context}} konnten nicht aktualisiert werden!",
        "loadingError": "{{context}} konnten nicht geladen werden!",
        "loadError": "{{context}} konnte nicht geladen werden!"
    }
}
