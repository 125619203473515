"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var AdminAccounts_1 = require("../screens/Admin/AdminAccounts");
var useHidden = function () {
    var theme = (0, material_1.useTheme)();
    var large = (0, material_1.useMediaQuery)(theme.breakpoints.up('lg'));
    var medium = (0, material_1.useMediaQuery)(theme.breakpoints.up('md'));
    var small = (0, material_1.useMediaQuery)(theme.breakpoints.up('sm'));
    var exLarge = (0, material_1.useMediaQuery)(theme.breakpoints.up('xl'));
    var exSmall = (0, material_1.useMediaQuery)(theme.breakpoints.up('xs'));
    return function (element) {
        var size = exSmall;
        if (element) {
            if (element === AdminAccounts_1.HiddenAttr.smUp)
                size = small;
            if (element === AdminAccounts_1.HiddenAttr.mdUp)
                size = medium;
            if (element === AdminAccounts_1.HiddenAttr.lgUp)
                size = large;
            if (element === AdminAccounts_1.HiddenAttr.xlUp)
                size = exLarge;
        }
        return size;
    };
};
exports.default = useHidden;
