"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPostingType = exports.getSingleDeferredPost = exports.getIndividualizedText = exports.getUpdateTemplateId = exports.getUpdatedPostTemplate = exports.isLoadingTMFile = exports.getPageSummary = exports.getTemporaryMediaFile = exports.getMediaFile = exports.getMediaData = exports.getTemplates = exports.getAboTopics = exports.getDeferredPosts = exports.getTags = void 0;
var reselect_1 = require("reselect");
exports.getTags = (0, reselect_1.createSelector)(function (state) { return state.postingStore.tags; }, function (state) { return state; });
exports.getDeferredPosts = (0, reselect_1.createSelector)(function (state) { return state.postingStore.deferredPosts; }, function (state) { return state; });
exports.getAboTopics = (0, reselect_1.createSelector)(function (state) { return state.postingStore.aboTopics; }, function (state) { return state; });
exports.getTemplates = (0, reselect_1.createSelector)(function (state) { return state.postingStore.templates; }, function (state) { return state; });
exports.getMediaData = (0, reselect_1.createSelector)(function (state) { return state.postingStore.mediaFiles; }, function (state) { return state; });
exports.getMediaFile = (0, reselect_1.createSelector)(function (state) { return state.postingStore.mediaFile; }, function (state) { return state; });
exports.getTemporaryMediaFile = (0, reselect_1.createSelector)(function (state) { return state.postingStore.temporaryMediaFile; }, function (state) { return state; });
exports.getPageSummary = (0, reselect_1.createSelector)(function (state) { return state.postingStore.pageResource; }, function (state) { return state; });
exports.isLoadingTMFile = (0, reselect_1.createSelector)(function (state) { return state.postingStore.loadingTMFile; }, function (state) { return state; });
exports.getUpdatedPostTemplate = (0, reselect_1.createSelector)(function (state) { return state.postingStore.updatedPostTemplate; }, function (state) { return state; });
exports.getUpdateTemplateId = (0, reselect_1.createSelector)(function (state) { return state.postingStore.updateTemplateId; }, function (state) { return state; });
exports.getIndividualizedText = (0, reselect_1.createSelector)(function (state) { return state.postingStore.individualizedText; }, function (state) { return state; });
exports.getSingleDeferredPost = (0, reselect_1.createSelector)(function (state) { return state.postingStore.deferredPostById; }, function (state) { return state; });
exports.getPostingType = (0, reselect_1.createSelector)(function (state) { return state.postingStore.postingType; }, function (state) { return state; });
