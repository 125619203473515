"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonReducer = void 0;
var typescript_fsa_reducers_1 = require("typescript-fsa-reducers");
var material_1 = require("@mui/material");
var immer_1 = require("immer");
var common_actions_1 = require("./common.actions");
var INITIAL_STATE = {
    theme: (0, material_1.createTheme)(),
    socialMediaNetworks: {
        facebook: true,
    },
    requests: [],
};
var triggerProfessocialMessage = function (data) {
    window.dispatchEvent(new CustomEvent('ProfessocialMessage', { detail: data }));
};
exports.CommonReducer = (0, typescript_fsa_reducers_1.reducerWithInitialState)(INITIAL_STATE)
    .case(common_actions_1.setTheme, function (state, theme) {
    return (__assign(__assign({}, state), { theme: theme }));
})
    .case(common_actions_1.setUser, function (state, user) {
    return (__assign(__assign({}, state), { user: user }));
})
    .case(common_actions_1.setLanguage, function (state, language) {
    return (__assign(__assign({}, state), { language: language }));
})
    /**
       * Message Management
       */
    .case(common_actions_1.setMessage, function (state, message) {
    // Fix - wait while the reducer is executing
    setTimeout(function () { return triggerProfessocialMessage(message); });
    return (0, immer_1.produce)(state, function (draft) {
        draft.message = message;
    });
})
    /**
       *  Requests Management
       */
    .case(common_actions_1.requestStarted, function (state, action) {
    return (0, immer_1.produce)(state, function (draft) {
        // filter success state out
        draft.requests.splice(draft.requests.findIndex(function (request) { return request.status === 'success'; }), 1);
        draft.requests.push({ type: action.type, status: 'pending', inProgress: true, error: null, id: action.id, network: action.network });
    });
})
    .case(common_actions_1.requestFinished, function (state, action) {
    return (0, immer_1.produce)(state, function (draft) {
        var index = draft.requests.findIndex(function (request) { return request.type === action.type && request.id === action.id; });
        if (index !== -1) {
            draft.requests[index].inProgress = false;
            draft.requests[index].status = 'success';
            draft.requests[index].error = null;
        }
        setTimeout(function () { return action.message && triggerProfessocialMessage({
            severity: action.message.severity,
            message: action.message.message,
            eventType: action.message.eventType,
        }); });
    });
})
    .case(common_actions_1.requestFailed, function (state, action) {
    return (0, immer_1.produce)(state, function (draft) {
        var index = draft.requests.findIndex(function (request) { return request.type === action.type && request.id === action.id; });
        if (index !== -1) {
            draft.requests[index].inProgress = false;
            draft.requests[index].status = 'failed';
            draft.requests[index].error = action.error;
            draft.requests[index].id = undefined;
        }
        setTimeout(function () { return action.message && triggerProfessocialMessage({
            severity: action.message.severity,
            message: action.message.message,
            eventType: action.message.eventType,
        }); });
    });
})
    .case(common_actions_1.clearRequests, function (state) {
    return (0, immer_1.produce)(state, function (draft) {
        draft.requests = [];
    });
});
