"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var getDefaultTheme = function () {
    var theme = {
        typography: {
            fontSize: 12,
        },
        overrides: {
            MuiAutocomplete: {
                option: {
                    '&[data-focus="true"]': {
                        color: '#3893C8',
                    },
                },
            },
            MuiOutlinedInput: {
                root: {
                    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                        border: '2px solid',
                        borderColor: '#3893C8',
                    },
                    '&$focused $notchedOutline': {
                        borderColor: '#3893C8',
                        borderWidth: 1,
                    },
                },
            },
            MuiTabs: {
                root: {
                    backgroundColor: '#f5f5f5',
                    borderTop: '2px solid',
                    borderColor: '#ebe7e9',
                },
            },
            MuiTab: {
                root: {
                    '&$selected': {
                        backgroundColor: '#ffffff',
                        borderTop: '3px solid',
                        borderColor: '#3893C8',
                        color: '#3893C8',
                    },
                },
            },
            MuiButton: {
                root: {
                    border: '1px solid #3893C8',
                    color: '#3893C8',
                    minWidth: '0',
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: '#fafafa',
                        border: '1px solid #3893C8',
                    },
                },
                outlinedSecondary: {
                    border: '1px solid #3893C8',
                    color: '#3893C8',
                    '&:hover': {
                        backgroundColor: '#fafafa',
                        border: '1px solid #3893C8',
                    },
                },
                outlinedPrimary: {
                    color: '#ffffff',
                    backgroundColor: '#3893C8',
                    border: '1px solid #3893C8',
                    '&:hover': {
                        backgroundColor: '#2985ba',
                        border: '1px solid #2985ba',
                    },
                },
            },
            MuiPickersToolbarButton: {
                toolbarBtn: {
                    border: 'none',
                    '&:hover': {
                        backgroundColor: 'transparent',
                        border: 'none',
                    },
                },
            },
            MuiPickersToolbarText: {
                toolbarTxt: {
                    '&:hover': {
                        color: '#3893C8',
                    },
                },
            },
        },
        palette: {
            common: {
                white: '#ffffff',
                black: '#000000',
            },
            grey: {
                A100: '#f7f7f7',
                A200: '#ebe7e9',
                A700: '#0e1121',
            },
            background: {
                default: '#fafafa',
                paper: '#ffffff',
            },
            primary: {
                main: '#2a3042',
                light: '#3893C8',
                dark: '#262b3b',
            },
            secondary: {
                main: '#3893C8',
            },
        },
    };
    return (0, material_1.createTheme)(theme);
};
exports.default = getDefaultTheme;
