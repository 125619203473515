"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useGalleryWidth = function (ref, trigger, elemSize, items, maxItemsProRow) {
    if (elemSize === void 0) { elemSize = 110; }
    if (items === void 0) { items = 20; }
    var _a = (0, react_1.useState)(items), itemsPerPage = _a[0], setItemsPerPage = _a[1];
    var _b = (0, react_1.useState)(elemSize), elementSize = _b[0], setElementSize = _b[1];
    (0, react_1.useEffect)(function () {
        setElementSize(elemSize);
    }, []);
    (0, react_1.useEffect)(function () {
        setItemsPerPage(items);
    }, [items]);
    (0, react_1.useEffect)(function () {
        var timeout;
        var handleResize = function () {
            if (ref.current) {
                timeout = setTimeout(function () {
                    var containerWidth = ref.current.offsetWidth;
                    var elemProRow = Math.floor(containerWidth / elemSize);
                    if (maxItemsProRow) {
                        elemProRow = maxItemsProRow;
                        if (containerWidth < 580) {
                            elemProRow = 2;
                        }
                        if (containerWidth < 385) {
                            elemProRow = 1;
                        }
                    }
                    var remainingSpace = containerWidth - (elemProRow * elementSize);
                    setItemsPerPage(maxItemsProRow ? 9 : elemProRow * 4);
                    setElementSize(remainingSpace / elemProRow + elementSize - 5.2); // minus 5px margin + 0.2 interference leveled
                }, 200);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return function () {
            window.removeEventListener('resize', handleResize);
            clearTimeout(timeout);
        };
    }, [trigger, itemsPerPage, ref]);
    return { itemsPerPage: itemsPerPage, elementSize: elementSize };
};
exports.default = useGalleryWidth;
