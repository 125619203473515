"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_leaflet_1 = require("react-leaflet");
var material_1 = require("@mui/material");
var styles_1 = require("@mui/styles");
var useStyles = (0, styles_1.makeStyles)(function (theme) { return ({
    map: {
        height: '60vh',
        width: '100%',
        borderRadius: 2,
        borderWidth: 'thin',
    },
    mapBox: {
        height: '60vh',
    },
}); });
var LocationMap = function (props) {
    var classes = useStyles();
    var position = props.position;
    var _a = (0, react_1.useState)(false), isCssElementLoaded = _a[0], setCssElementLoaded = _a[1];
    (0, react_1.useEffect)(function () {
        var linkElement = document.createElement('link');
        linkElement.setAttribute('rel', 'stylesheet');
        linkElement.setAttribute('type', 'text/css');
        linkElement.setAttribute('href', 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css');
        document.head.appendChild(linkElement);
        setCssElementLoaded(true);
    }, []);
    var _b = (0, react_1.useState)(false), isScriptElementLoaded = _b[0], setScriptElementLoaded = _b[1];
    (0, react_1.useEffect)(function () {
        var linkElement = document.createElement('script');
        linkElement.setAttribute('type', 'text/javascript');
        linkElement.setAttribute('src', 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.js');
        document.head.appendChild(linkElement);
        setScriptElementLoaded(true);
    }, []);
    function ChangeMapView() {
        var map = (0, react_leaflet_1.useMap)();
        map.flyTo(position, map.getZoom());
        return null;
    }
    function LocationMarker() {
        return position === null ? null : (react_1.default.createElement(react_leaflet_1.Marker, { position: position },
            react_1.default.createElement(react_leaflet_1.Popup, null, "You are here")));
    }
    return (react_1.default.createElement(react_1.default.Fragment, null, isCssElementLoaded && isScriptElementLoaded &&
        react_1.default.createElement(material_1.Box, { className: classes.mapBox },
            react_1.default.createElement(react_leaflet_1.MapContainer, { className: classes.map, center: position, zoom: 13 },
                react_1.default.createElement(react_leaflet_1.TileLayer, { attribution: '\u00A9 <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors', url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' }),
                react_1.default.createElement(ChangeMapView, null),
                react_1.default.createElement(LocationMarker, null)))));
};
exports.default = LocationMap;
