"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValid = void 0;
var material_1 = require("@mui/material");
var react_1 = __importStar(require("react"));
var isValid = function (val) {
    var regexp = /^\d{0,2}?:?\d{0,2}$/;
    var _a = val.split(':'), hoursStr = _a[0], minutesStr = _a[1];
    if (!regexp.test(val)) {
        return false;
    }
    var hours = Number(hoursStr);
    var minutes = Number(minutesStr);
    var isValidHour = function (hour) { return Number.isInteger(hour) && hour >= 0 && hour < 24; };
    var isValidMinutes = function (minutes) { return (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes); };
    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }
    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }
    var valArr = val.indexOf(':') !== -1
        ? val.split(':')
        : [val];
    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
        return false;
    }
    return !(valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59));
};
exports.isValid = isValid;
var TimeInput = function (_a) {
    var initTime = _a.initTime, onFocusHandler = _a.onFocusHandler, onBlurHandler = _a.onBlurHandler, onTimeChange = _a.onTimeChange, type = _a.type, size = _a.size, placeholder = _a.placeholder, label = _a.label;
    var _b = (0, react_1.useState)(initTime || ''), time = _b[0], setTime = _b[1];
    var _input = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        setTimeout(function () {
            _input && _input.current && _input.current.focus();
        }, 0);
    });
    var lastVal = '';
    var onChangeHandler = function (val) {
        if (val === time) {
            return;
        }
        if ((0, exports.isValid)(val) || val === '' || val === undefined) {
            if (val === '' || val === undefined)
                onTimeChange && onTimeChange(val);
            if (val.length === 2 && lastVal.length !== 3 && val.indexOf(':') === -1) {
                val = val + ':';
            }
            if (val.length === 2 && lastVal.length === 3) {
                val = val.slice(0, 1);
            }
            if (val.length > 5) {
                return false;
            }
            lastVal = val;
            setTime(val);
            if (val.length === 5) {
                onTimeChange && onTimeChange(val);
            }
        }
    };
    return (react_1.default.createElement(material_1.TextField, { type: type || 'tel', placeholder: placeholder, value: time, label: label, size: size, onChange: function (e) { return onChangeHandler(e.target.value); }, onFocus: (onFocusHandler) ? function (e) { return onFocusHandler(e); } : undefined, onBlur: (onBlurHandler) ? function (e) { return onBlurHandler(e); } : undefined, ref: _input, InputLabelProps: {
            shrink: true,
        } }));
};
exports.default = TimeInput;
