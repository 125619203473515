{
    "facebookSettingsCommon": {
        "profileImage": "Profilbild",
        "yourProfileImage": "Profil- & Titelbild",
        "pageInfo": "Seiteninformationen",
        "imprint": "Impressum",
        "from": "von",
        "to": "bis",
        "info": "Info",
        "takeTemplate": "Vorlage verwenden",
        "template": "Vorlage",
        "useImage": "Bild verwenden",
        "saveChanges": "Änderungen speichern",
        "titleAreaImage": "Ihr Titelbild",
        "getCroppedImage": "Bereich auswählen",
        "preview": "Vorschau",
        "deleteImage": "Bild entfernen",
        "openHours": "Öffnungszeiten",
        "adressData": "Address-Daten",
        "deleteLink": "Link entfernen",
        "adpWebsite": "ADP-Website",
        "zip": "Postleitzahl",
        "shortDesc": "Über uns",
        "detailedDesc": "Ausführliche Beschreibung",
        "telNumber": "Telefonnummer",
        "city": "Stadt",
        "email": "E-Mail-Adresse",
        "emailFalse": "E-Mail-Adresse ist falsch",
        "street": "Straße",
        "pageCategory": "Seitenkategorie",
        "autoUpdate": "Impressum automatisch aktualisieren",
        "uploadImageHint": "Laden Sie ein neues Bild/Video hoch oder wählen sie ein Bild/Video aus der Mediendatenbank.",
        "autoUpdateDesc": "Ist diese Option aktiviert, so wird das Facebook-Impressum jede Nacht automatisch mit den hinterlegten Stammdaten synchronisiert.",
        "infoBoxText": "Pflegen Sie hier die wichtigsten Informationen zu Ihrer Facebook-Fanseite. Die vollständige Übersicht über alle Daten finden Sie auf Ihrer Fanseite unter",
        "monday": "Montag",
        "tuesday": "Dienstag",
        "wednesday": "Mittwoch",
        "thursday": "Donnerstag",
        "friday": "Freitag",
        "saturday": "Samstag",
        "sunday": "Sonntag",
        "templateShort": "Dein 3m5.Partner für Versicherung und Vorsorge.",
        "templateLong": "Willkommen auf unserer Facebook-Seite!\n\nWir sind dein 3m5.-Partner für Versicherung und Vorsorge.\nWir sind an deiner Seite, wenn es um qualifizierte und verständliche Beratung geht und bieten dir umfassende Betreuung und schnelle Schadensregulierung.\nHast du Fragen zu unserem Produkt- und Serviceangebot? Kontaktiere uns gern.\n\nDein 3m5.-Partner\nDani osterrat.\n\nMehr Informationen zum Team unter: https://www.testing.3m5.de/de/Agentur/MeinTeam",
        "imprintTemplate": "Vollständiges Impressum unter: https://www.[Registernummer].3m5.de/de/Agentur/Impressum\n\nmit eigenem Text ausfüllen",
        "syncEnabled": "Synchronisierung erfolgreich aktiviert",
        "syncDisabled": "Synchronisierung erfolgreich deaktiviert"
    },
    "facebookSettingsMessages": {
        "getAllFacebookCategoriesError": "Alle Facebook-Kategorien konnten nicht geladen werden."
    }
}
