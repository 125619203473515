"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.facebookSettingsRootReducer = void 0;
var redux_1 = require("redux");
var facebook_reducer_1 = require("./facebook/facebook.reducer");
var common_reducer_1 = require("./common/common.reducer");
exports.facebookSettingsRootReducer = (0, redux_1.combineReducers)({
    facebookStore: facebook_reducer_1.facebookReducer,
    commonStore: common_reducer_1.CommonReducer,
});
