"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hexToRGB = void 0;
function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16);
    var g = parseInt(hex.slice(3, 5), 16);
    var b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    }
    else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
}
exports.hexToRGB = hexToRGB;
var transformColor = function (hex, percent, darker) {
    // strip the leading # if it's there
    hex = hex.replace(/^\s*#|\s*$/g, '');
    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (hex.length === 3) {
        hex = hex.replace(/(.)/g, '$1$1');
    }
    var r = parseInt(hex.substr(0, 2), 16);
    var g = parseInt(hex.substr(2, 2), 16);
    var b = parseInt(hex.substr(4, 2), 16);
    if (darker) {
        return '#' +
            ((0 | (1 << 8) + r - r * percent / 100).toString(16)).substr(1) +
            ((0 | (1 << 8) + g - g * percent / 100).toString(16)).substr(1) +
            ((0 | (1 << 8) + b - b * percent / 100).toString(16)).substr(1);
    }
    else {
        return '#' +
            ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
            ((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
            ((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
    }
};
exports.default = transformColor;
