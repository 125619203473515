"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDuration = void 0;
var react_i18next_1 = require("react-i18next");
function getDuration(timestamp) {
    var actualTimeStamp = new Date().getTime();
    var differenceInSeconds = (actualTimeStamp - timestamp) / 1000;
    var t = (0, react_i18next_1.useTranslation)().t;
    var output = "".concat(differenceInSeconds, " s");
    // Jahre
    if (differenceInSeconds >= 31536000) {
        var yearCount = Math.floor(differenceInSeconds / 86400);
        output = "".concat(t('postCommon:for'), " ").concat(yearCount === 1 ? yearCount + ' ' + t('postCommon:year') : yearCount + ' ' + t('postCommon:years'), "}");
    }
    // Monate
    if (differenceInSeconds >= 2419001) {
        output = "".concat(t('postCommon:for'), " ").concat(Math.floor(differenceInSeconds / 2628000), " ").concat(t('postCommon:month'));
    }
    // Wochen
    if (differenceInSeconds < 2419000) {
        output = "".concat(t('postCommon:for'), " ").concat(Math.floor(differenceInSeconds / 604800), " ").concat(t('postCommon:week'));
    }
    // Tage
    if (differenceInSeconds < 518500) {
        var daysCount = Math.floor(differenceInSeconds / 86400);
        output = "".concat(t('postCommon:for'), " ").concat(daysCount === 1 ? daysCount + ' ' + t('postCommon:day') : daysCount + ' ' + t('postCommon:days'));
    }
    // Stunden
    if (differenceInSeconds < 86400) {
        output = "".concat(t('postCommon:for'), " ").concat(Math.floor(differenceInSeconds / 3600), " ").concat(t('postCommon:hour'));
    }
    // Minuten
    if (differenceInSeconds < 3600) {
        output = "".concat(t('postCommon:for'), " ").concat(Math.floor(differenceInSeconds / 60), " ").concat(t('postCommon:minute'));
    }
    return output;
}
exports.getDuration = getDuration;
