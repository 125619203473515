"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.datesAreOnSameMonth = exports.datesAreOnSameDay = void 0;
var datesAreOnSameDay = function (first, second) {
    return first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();
};
exports.datesAreOnSameDay = datesAreOnSameDay;
var datesAreOnSameMonth = function (first, second) {
    return first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth();
};
exports.datesAreOnSameMonth = datesAreOnSameMonth;
