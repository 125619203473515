"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.namedRequestData = exports.namedRequestStatus = exports.namedRequestInProgress = exports.getLicensePermissions = exports.getUser = exports.getTheme = void 0;
var reselect_1 = require("reselect");
exports.getTheme = (0, reselect_1.createSelector)(function (state) { return state.commonStore.theme; }, function (state) { return state; });
exports.getUser = (0, reselect_1.createSelector)(function (state) { return state.commonStore.user; }, function (state) { return state; });
exports.getLicensePermissions = (0, reselect_1.createSelector)(function (state) { return state.commonStore.licensePermissions; }, function (state) { return state; });
exports.namedRequestInProgress = (0, reselect_1.createSelector)([
    function (state) { return state.commonStore.requests; },
    function (state, type) { return type; },
], function (state, type) {
    return state.filter(function (request) { return request.type === type && request.inProgress; }).length > 0;
});
exports.namedRequestStatus = (0, reselect_1.createSelector)([
    function (state) { return state.commonStore.requests; },
    function (state, type) { return type; },
], function (state, type) {
    var _a;
    return (_a = state.find(function (request) { return request.type === type; })) === null || _a === void 0 ? void 0 : _a.status;
});
exports.namedRequestData = (0, reselect_1.createSelector)([
    function (state) { return state.commonStore.requests; },
    function (state, type) { return type; },
], function (state, type) {
    return state.find(function (request) { return request.type === type; });
});
